import React, { useState, useEffect } from "react";
import "./Css/Collections.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import right from "../Assets/rightArrow_white.svg";
import leftArrowIcon from "../Assets/leftArrowIcon.svg";
import rightArrowSvg from "../Assets/rightArrowIcon.svg";
import { APL_LINK } from "../ServiceConnection/serviceconnection";
import { handleLinkClick } from "../CommonJquery/CommonJquery";
import { Link } from "react-router-dom";
import $ from "jquery";
const Cuisine = ({ data, SEOloop, ImageLink }) => {
  //browse cities section

  // Custom Next Arrow
  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <img
        className={className}
        src={rightArrowSvg}
        style={{ ...style, display: "block" }}
        onClick={onClick}
        alt="dfoodo"
      />
    );
  };

  // Custom Prev Arrow
  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <img
        src={leftArrowIcon}
        className={className}
        style={{
          ...style,
          display: "block",
          opacity: "1",
        }}
        alt="dfoodo"
        onClick={onClick}
      />
    );
  };
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const match_and_return_seo_link = (v_id) => {
    let data_seo_link_final = "/restro/by_cuisine/" + v_id;
    let data_seo_link = data_seo_link_final;
    if (SEOloop) {
      const matchedItem = SEOloop.find((data) => {
        return data_seo_link === data.call_function_name;
      });

      if (matchedItem) {
        data_seo_link_final = matchedItem.pretty_function_name;
      }
    }
    return data_seo_link_final;
  };
  //mobile condition
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 992) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobile]);
  return (
    <div>
      {/* browse other cities */}
      <section>
        <div className="browse_otherCities_section">
          <div className="container-lg">
            <div className="cities_mapped ">
              {isMobile ? (
                <div className="scroll-container">
                  {data &&
                    data.length > 0 &&
                    data.map((venue, index) => (
                      <div key={index} className="city-item city-item2">
                        <Link
                          onClick={() =>
                            handleLinkClick(
                              match_and_return_seo_link(venue.primary_id)
                            )
                          }
                        >
                          <div className="cuisine_card">
                            <div className="cuisine_content">
                              <div className="cuisine_back">
                                <div className="cuisine_back-content">
                                  {/* <img
                                    className="image_set_cuisine"
                                    src={`${
                                      APL_LINK + ImageLink + venue.image_name
                                    }`}
                                    alt={venue.cuisine_name}
                                  /> */}

                                  <LazyLoadImage
                                    className="image_set_cuisine"
                                    src={`${
                                      APL_LINK + ImageLink + venue.image_name
                                    }`}
                                    alt={venue.cuisine_name}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="city_description2">
                            <h6>{venue.cuisine_name}</h6>
                          </div>
                        </Link>
                      </div>
                    ))}
                </div>
              ) : (
                <>
                  {data && data.length > 0 && (
                    <Slider {...settings}>
                      {data &&
                        data.length > 0 &&
                        data.map((venue, index) => (
                          <div key={index} className="city-item city-item2 ">
                            <Link
                              onClick={() =>
                                handleLinkClick(
                                  match_and_return_seo_link(venue.primary_id)
                                )
                              }
                            >
                              <div className="cuisine_card">
                                <div className="cuisine_content">
                                  <div className="cuisine_back">
                                    <div className="cuisine_back-content">
                                      {/* <img
                                        className="image_set_cuisine"
                                        src={`${
                                          APL_LINK +
                                          ImageLink +
                                          venue.image_name
                                        }`}
                                        alt={venue.cuisine_name}
                                      /> */}

                                      <LazyLoadImage
                                        className="image_set_cuisine"
                                        src={`${
                                          APL_LINK +
                                          ImageLink +
                                          venue.image_name
                                        }`}
                                        alt={venue.cuisine_name}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="city_description2">
                                <h6>{venue.cuisine_name}</h6>
                              </div>
                            </Link>
                          </div>
                        ))}
                    </Slider>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Cuisine;
