import React, { useState, useEffect } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "./Css/Reviews.css";
import DOMPurify from "dompurify";
import upArrow from "../Assets/downArrowBlack.svg";
import profile from "../Assets/userReviewProfilImg.svg";
import experience from "../Assets/experience.png";
import like from "../Assets/like.svg";
import likeRed from "../Assets/likeRed.svg";
import AreYouAVenueOwner from "./AreYouAVenueOwner.js";
import DownloadApp from "./DownloadApp.js";
import star from "../Assets/star2.svg";
import { Modal, Button } from "react-bootstrap";
import { Rating } from "react-simple-star-rating";
import { retrieveData } from "../LocalConnection/LocalConnection.js";
import {
  inputdateformateChange,
  handleError,
  check_vaild_save,
  combiled_form_data,
  empty_form,
} from "../CommonJquery/CommonJquery";
import {
  server_post_data,
  save_like,
  save_review,
  get_all_faq_website,
} from "../ServiceConnection/serviceconnection.js";
let customer_id = "0";
let customer_name = "0";

const Reviews = ({ tabOpen, review, venuedata, like_data }) => {
  customer_id = retrieveData("customer_id");
  customer_name = retrieveData("customer_name");
  const tanOpen = tabOpen;
  const normalizedValue = venuedata.rating / 5;

  const [activeIndex, setActiveIndex] = useState(null);
  const [getFaq, SetFaq] = useState([]);
  const handleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };
  const reviews = review;
  const progress1 = 1;
  const progress2 = 2;
  const progress3 = 3;
  const progress4 = 4;
  const progress5 = 5;
  const [HeartImg, setHeartImages] = useState(like_data);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [likeCaunter, SetLikeCaunter] = useState(0);

  const getProgressPercentage = (progress) => {
    return (progress / 5) * 100;
  };

  // // load more reviews:
  const initialReviewsToShow = 3;
  const incrementAmount = 5;
  const [reviewsToShow, setReviewsToShow] = useState(initialReviewsToShow);

  const handleLoadMore = () => {
    setReviewsToShow(reviewsToShow + incrementAmount);
  };

  // reviews modal
  // rating system
  const [showModal, setShowModal] = useState(false);
  const [ratings, setRatings] = useState([0, 0, 0, 0]);
  const [reviewText, setReviewText] = useState("");
  const [isFormComplete, setIsFormComplete] = useState(false);
  const handleClose = () => setShowModal(false);

  const handleRating = (rate, index) => {
    const newRatings = [...ratings];
    newRatings[index] = rate;
    setRatings(newRatings);
  };
  useEffect(() => {
    const allRatingsFilled = ratings.every((rating) => rating > 0);
    const textFilled = reviewText.trim() !== "";
    setIsFormComplete(allRatingsFilled && textFilled);
  }, [ratings, reviewText, tanOpen]);

  const handleSaveChangesdynamic = async (form_data, save_venueowner) => {
    let vaild_data = check_vaild_save(form_data);

    if (vaild_data) {
      setshowLoaderAdmin(true);
      let fd_from = combiled_form_data(form_data, null);
      ratings.map((item, index) => {
        fd_from.append(`ratings${index}`, item);
      });
      fd_from.append("comment_show", reviewText);
      fd_from.append("customer_name", customer_name);
      fd_from.append("customer_id", customer_id);
      fd_from.append("restaurant_id", venuedata.primary_id);
      await server_post_data(save_venueowner, fd_from)
        .then((Response) => {
          setshowLoaderAdmin(false);
          if (Response.data.error) {
            handleError(Response.data.message);
          } else {
            handleClose();
            empty_form(form_data);
            window.location.reload();
          }
        })
        .catch((error) => {
          setshowLoaderAdmin(false);
        });
    }
  };
  const like_save = async (primary_id, index) => {
    // seterror_show("");
    const fd_from = new FormData();
    setshowLoaderAdmin(true);
    fd_from.append("review_id", primary_id);
    fd_from.append("customer_id", customer_id);
    fd_from.append("restaurant_id", venuedata.primary_id);
    await server_post_data(save_like, fd_from)
      .then((Response) => {
        setshowLoaderAdmin(false);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          const updatedFavorites = HeartImg.some(
            (fav) => fav.review_id === primary_id
          )
            ? HeartImg.filter((fav) => fav.review_id !== primary_id) // Remove from favorites
            : [...HeartImg, { review_id: primary_id }];
          setHeartImages(updatedFavorites);
        }
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };

  const check_login_or_not = (primary_id, index, click_type) => {
    if (customer_id !== "0") {
      if (click_type === "0") {
        like_save(primary_id, index);
        SetLikeCaunter(likeCaunter + 1);
      } else {
        setShowModal(true);
      }
    } else {
      var event = new CustomEvent("customEvent");
      document.getElementById("login_check_jquery").dispatchEvent(event);
    }
  };

  const isFavorite = (venueId) => {
    return HeartImg.some((fav) => fav.review_id === venueId);
  };
  const master_data_get = async () => {
    const fd = new FormData();

    await server_post_data(get_all_faq_website, fd)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          SetFaq(Response.data.message.data);
        }
      })
      .catch((error) => {});
  };
  useEffect(() => {
    master_data_get();
  }, []);

  return (
    <>
      <section className="reviews_section">
        <div className="container p0a576">
          <h5>Reviews</h5>
          <div className="review_wrapper mt-5 row">
            <div
              className="col-md-6 col-5 left_section_circularProgressBar"
              style={{
                borderRight: "1px solid black",
                padding: "0rem",
              }}
            >
              <div className="circular_review_wrapper">
                <div className="Circualr_progressBar_section">
                  <CircularProgressbar
                    value={normalizedValue}
                    maxValue={1}
                    text={`${venuedata && venuedata.rating}/5.0`}
                    width={"120px"}
                  />
                  <span className="no_reviews">
                    <h6>Fabulous</h6>
                    <p>{venuedata && venuedata.total_reviews} reviews</p>
                  </span>
                </div>
                <div className="text_rating_sectin">
                  <span>
                    <p>
                      {venuedata && venuedata.total_location_rating_sum}/5.0
                    </p>
                    <p>Location</p>
                  </span>
                  <div
                    className="vr"
                    style={{
                      width: "1px",
                      background: "transparent",
                      border: "1px solid black",
                      padding: "0rem",
                      marginRight: "0.2rem",
                    }}
                  ></div>
                  <span>
                    <p>{venuedata && venuedata.total_service_rating_sum}/5.0</p>
                    <p>Service</p>
                  </span>
                  <div
                    className="vr"
                    style={{
                      width: "1px",
                      background: "transparent",
                      border: "1px solid black",
                      padding: "0rem",
                      marginRight: "0.2rem",
                    }}
                  ></div>
                  {/* <span>
                <p>{venuedata && venuedata.total_ambience_rating_sum}/5.0</p>
                <p>Ambience</p>
              </span>
              <div
                className="vr"
                style={{
                  width: "1px",
                  background: "transparent",
                  border: "1px solid black",
                  padding: "0rem",
                  marginRight: "0.2rem",
                }}
              ></div> */}
                  <span>
                    <p>
                      {venuedata && venuedata.total_ambience_rating_sum}/5.0
                    </p>
                    <p>Food</p>
                  </span>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-7 mx-auto d-flex">
              <div className="linear_progressBar_section">
                <div className="progressbar_text_wrapper">
                  <div className="progress_text">
                    <span>{progress1}</span>
                    <img src={star} alt="Star" />
                  </div>
                  <div className="progress_container">
                    <div
                      className="progress_bar"
                      style={{ width: `${getProgressPercentage(progress1)}%` }}
                    ></div>
                  </div>
                </div>

                <div className="progressbar_text_wrapper">
                  <div className="progress_text">
                    <span>{progress2}</span>
                    <img src={star} alt="Star" />
                  </div>
                  <div className="progress_container">
                    <div
                      className="progress_bar"
                      style={{ width: `${getProgressPercentage(progress2)}%` }}
                    ></div>
                  </div>
                </div>

                <div className="progressbar_text_wrapper">
                  <div className="progress_text">
                    <span>{progress3}</span>
                    <img src={star} alt="Star" />
                  </div>
                  <div className="progress_container">
                    <div
                      className="progress_bar"
                      style={{ width: `${getProgressPercentage(progress3)}%` }}
                    ></div>
                  </div>
                </div>

                <div className="progressbar_text_wrapper">
                  <div className="progress_text">
                    <span>{progress4}</span>
                    <img src={star} alt="Star" />
                  </div>
                  <div className="progress_container">
                    <div
                      className="progress_bar"
                      style={{ width: `${getProgressPercentage(progress4)}%` }}
                    ></div>
                  </div>
                </div>

                <div className="progressbar_text_wrapper">
                  <div className="progress_text">
                    <span>{progress5}</span>
                    <img src={star} alt="Star" />
                  </div>
                  <div className="progress_container">
                    <div
                      className="progress_bar"
                      style={{ width: `${getProgressPercentage(progress5)}%` }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="text_rating_sectin">
              {/* <span>
                <p>{venuedata && venuedata.total_location_rating_sum}/5.0</p>
                <p>Location</p>
              </span> */}
              {/* <div
                className="vr"
                style={{
                  width: "1px",
                  background: "transparent",
                  border: "1px solid black",
                  padding: "0rem",
                  marginRight: "0.2rem",
                }}
              ></div> */}
              {/* <span>
                <p>{venuedata && venuedata.total_service_rating_sum}/5.0</p>
                <p>Service</p>
              </span> */}
              {/* <div
                className="vr"
                style={{
                  width: "1px",
                  background: "transparent",
                  border: "1px solid black",
                  padding: "0rem",
                  marginRight: "0.2rem",
                }}
              ></div> */}
              {/* <span>
                <p>{venuedata && venuedata.total_ambience_rating_sum}/5.0</p>
                <p>Ambience</p>
              </span>
              <div
                className="vr"
                style={{
                  width: "1px",
                  background: "transparent",
                  border: "1px solid black",
                  padding: "0rem",
                  marginRight: "0.2rem",
                }}
              ></div> */}
              {/* <span>
                <p>{venuedata && venuedata.total_ambience_rating_sum}/5.0</p>
                <p>Food</p>
              </span> */}
            </div>
          </div>
          <div className="experience">
            <img src={experience} alt="experience" />
          </div>
          {reviews &&
            reviews.length > 0 &&
            reviews.slice(0, reviewsToShow).map((review, index) => (
              <div key={index} className="user_review_container">
                <div className="user_review_wrapper">
                  <div>
                    <img src={profile} alt="profile" />
                  </div>
                  <div className="user_review_rowcontainer">
                    <div className="">
                      <div className="user_review_rowcontainer_name">
                        <h6>{review.customer_name}</h6>
                        <desc>{review.total_review}</desc>
                      </div>
                      <div className="user_review_rowcontainer_date">
                        {inputdateformateChange(review.entry_date)}
                      </div>
                    </div>
                    <div>{review.rating}/5.0</div>
                  </div>
                </div>
                <div className="user_review_description">
                  <p>{review.comment}</p>
                </div>
                <div className="user_review_like_report_section">
                  <span className="user_review_like">
                    <button
                      className="d-flex align-items-center"
                      style={{ border: "none", background: "transparent" }}
                      onClick={() =>
                        check_login_or_not(review.primary_id, index, "0")
                      }
                      type="button"
                    >
                      {isFavorite(review.primary_id) ? (
                        <>
                          <img
                            src={likeRed}
                            alt="likeRed"
                            className="LikeImgg"
                          />
                          <p style={{ marginLeft: "4px" }}>Liked</p>
                        </>
                      ) : (
                        <>
                          <img src={like} alt="like" className="LikeI" />
                          <p style={{ marginLeft: "4px" }}>Like</p>
                        </>
                      )}
                    </button>
                  </span>
                  <div style={{ cursor: "text", fontSize: "13px" }}>
                    <label>{likeCaunter}</label>
                  </div>
                </div>
                <hr />
              </div>
            ))}
          {tanOpen === "reviews" && (
            <>
              {reviews.length > reviewsToShow && (
                <div className="write_review_button">
                  <button id="load_more_button" onClick={handleLoadMore}>
                    Load More
                  </button>
                </div>
              )}
            </>
          )}
          {/* <div
            className="write_review_button"
            onClick={() => check_login_or_not("0", "0", "1")}
          >
            <button>Write a Review</button>
          </div> */}
        </div>
      </section>
      {/* FAQ Section here */}
      <section className="faq_section">
        <div className="container">
          <div className="faq_section_container">
            <div className="accordion_container w-100">
              <div className="faq_section_container_heading">
                <h2>FAQS</h2>
              </div>
              <div className="accordion">
                {getFaq.map((item, index) => (
                  <div key={index} className="accordion-item bgColorr">
                    <div
                      className={`accordion-title ${
                        index === activeIndex ? "active" : ""
                      }`}
                      onClick={() => handleClick(index)}
                    >
                      {item.question_name}
                      <span className="dropdown-icon">
                        <img
                          src={upArrow}
                          alt="toggle arrow"
                          className={`arrow ${
                            index === activeIndex ? "up" : ""
                          }`}
                        />
                      </span>
                    </div>
                    {index === activeIndex && (
                      <div className="accordion-content">
                        <p
                          className="accordion-content-text"
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(item.answer_name),
                          }}
                        />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <DownloadApp />
      </section>
      <section>
        <AreYouAVenueOwner />
      </section>
      <Modal show={showModal} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title
            style={{
              color: "var(--primary-color)",
              fontWeight: "600",
              fontFamily: "Roboto",
            }}
          >
            {venuedata && venuedata.venue_name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="user_detail_section">
            <div className="user_image">
              <img src={profile} alt="profile" />
            </div>
            <div className="user_details">
              <h6>{customer_name} </h6>
              <p>Posting Publicly </p>
            </div>
          </div>
          <form className="venue-registration-form" id="vanueregistration">
            <div className="stars_rating_section">
              {["Overall", "Location ", "Service", "Ambience", "Food"].map(
                (label, index) => (
                  <div key={index} className="mb-3 stars_text_wrapper">
                    <label>{label}</label>
                    <Rating
                      onClick={(rate) => handleRating(rate, index)}
                      ratingValue={ratings[index]}
                      size={30}
                      label
                      transition
                      fillColor="var(--primary-color)"
                      emptyColor="gray"
                    />
                  </div>
                )
              )}
            </div>
            <div className="mb-3">
              <textarea
                rows={3}
                maxLength={300}
                id="comment"
                name="comment"
                onChange={(e) => setReviewText(e.target.value)}
                className="form-control trio_mandatory"
                placeholder="Share details of your own experience at this place"
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleClose}
            style={{ backgroundColor: "grey" }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() =>
              handleSaveChangesdynamic("vanueregistration", save_review)
            }
            style={{
              backgroundColor: isFormComplete ? "var(--primary-color)" : "grey",
              outline: "none",
              border: "none",
              color: "var(--white)",
              padding: "0.43rem 1rem",
            }}
            className="psotBtn"
          >
            Post
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Reviews;
