import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { retrieveData } from "../LocalConnection/LocalConnection.js";
import "./Css/Mobilefotter.css";

const Footer = () => {
  let customer_id = retrieveData("customer_id") || "0";

  const [activeSection, setActiveSection] = useState(() => {
    const storedSection = localStorage.getItem("activeSection");
    return storedSection ? storedSection : "";
  });

  const [isCustomEventTriggered, setIsCustomEventTriggered] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (!activeSection) {
      // If no section is active and user hasn't selected one, handle it here (optional)
      setActiveSection("");
    }
  }, [activeSection]);

  const handleSectionClick = (section, path) => {
    setActiveSection(section);
    localStorage.setItem("activeSection", section);
    handleNavigation(path, section); // Pass section to handleNavigation
  };

  const handleNavigation = (path, section) => {
    if (customer_id !== "0") {
      setActiveSection(section);
      navigate(path);
      window.location.reload();
    } else {
      if (path !== "/") {
        const event = new CustomEvent("customEvent");
        document.getElementById("login_check_jquery").dispatchEvent(event);
        setActiveSection("home");
        setIsCustomEventTriggered(true);
      }
      navigate("/");
    }
  };

  return (
    <div className="footer mobile">
      <div
        className={`footer-item ${activeSection === "home" ? "active" : ""}`}
        onClick={() => handleSectionClick("home", "/")}
      >
        <i className="fas fa-home fa-2x"></i>
        <span>Home</span>
      </div>
      <div
        className={`footer-item ${
          activeSection === "booking" && !isCustomEventTriggered ? "active" : ""
        }`}
        onClick={() => handleSectionClick("booking", "/profile")}
      >
        <i className="fas fa-calendar-alt fa-2x"></i>
        <span>Booking</span>
      </div>
      <div
        className={`footer-item ${
          activeSection === "favorite" && !isCustomEventTriggered
            ? "active"
            : ""
        }`}
        onClick={() => handleSectionClick("favorite", "/profile")}
      >
        <i className="fas fa-heart fa-2x"></i>
        <span>Favorite</span>
      </div>
      <div
        className={`footer-item ${
          activeSection === "profile" && !isCustomEventTriggered ? "active" : ""
        }`}
        onClick={() => handleSectionClick("profile", "/profile")}
      >
        <i className="fas fa-user fa-2x"></i>
        <span>Profile</span>
      </div>
      <div className="beforeCLas"></div>
    </div>
  );
};

export default Footer;
