// stepSlice.js

const initialState = {
  currentStep: 0,
};

const stepSlice = (state = initialState, action) => {
  switch (action.type) {
    case "NEXT_STEP":
      return {
        ...state,
        currentStep: state.currentStep + 1,
      };
    case "PREV_STEP":
      return {
        ...state,
        currentStep: state.currentStep > 0 ? state.currentStep - 1 : 0,
      };
    default:
      return state;
  }
};

export const nextStep = () => ({ type: "NEXT_STEP" });
export const prevStep = () => ({ type: "PREV_STEP" });

export default stepSlice;
