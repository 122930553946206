import React, { useState, useEffect } from "react";
import backButton from "../Assets/backButton.svg";
import tooltipIcon from "../Assets/tooltip.svg";
import Swal from "sweetalert2";
import "./Css/Newonboading.css";
import { retrieveData } from "../LocalConnection/LocalConnection";
import { handleError } from "../CommonJquery/CommonJquery";
import {
  sava_onboarding_form,
  get_last_resturant_data_save,
  server_post_data,
} from "../ServiceConnection/serviceconnection";
let staff_id = "0";
let default_restaurant_id_staff = "0";
const Onboadingstep8 = ({ nextStep, prevStep }) => {
  staff_id = retrieveData("staff_id");
  default_restaurant_id_staff = retrieveData("default_restaurant_id_staff");
  const [showLoader, setshowLoader] = useState(false);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [restaurantData, setrestaurantData] = useState([]);
  const [PerDayMaximum, setPerDayMaximum] = useState("");
  const [ApprovalPersonLimit, setApprovalPersonLimit] = useState("");
  const [BeforeBookingMin, setBeforeBookingMin] = useState("");
  const [TableTurnover, setTableTurnover] = useState("");
  const [RestaurantPrice, setRestaurantPrice] = useState("");
  const [selectedOption, setSelectedOption] = useState(null); // Single value for radio selection
  const [isFocused, setIsFocused] = useState(false);
  const [isFocused2, setIsFocused2] = useState(false);
  const [isFocused3, setIsFocused3] = useState(false);
  const [isFocused4, setIsFocused4] = useState(false);
  const [isFocused5, setIsFocused5] = useState(false);
  const handleRadioChange = (index) => {
    setSelectedOption(index); // Update selected option
  };

  const handleNext = () => {
    if (selectedOption === null) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please choose at least one option!",
        confirmButtonText: "OK",
        confirmButtonColor: "#3085d6",
      });
    } else {
      if (RestaurantPrice !== "" && RestaurantPrice > 1) {
        handleSaveChangesdynamic();
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Please Enter Restaurant Price Greater Then 1",
          confirmButtonText: "OK",
          confirmButtonColor: "#3085d6",
        });
      }
    }
  };
  const handleSaveChangesdynamic = async () => {
    setshowLoader(true);
    const form_data = new FormData();
    form_data.append("owner_staff_id", staff_id);
    form_data.append("default_restaurant_id", default_restaurant_id_staff);
    form_data.append("per_day_maximum", PerDayMaximum);
    form_data.append("approval_person_limit", ApprovalPersonLimit);
    form_data.append("before_booking_min", BeforeBookingMin);
    form_data.append("booking_slot_interval", selectedOption);
    form_data.append("table_turnover", TableTurnover);
    form_data.append("restaurant_price", RestaurantPrice);
    form_data.append("step_save", "8");
    await server_post_data(sava_onboarding_form, form_data)
      .then(async (Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          nextStep();
        }
        setshowLoader(false);
      })
      .catch((error) => {
        console.log(error);
        setshowLoader(false);
      });
  };

  useEffect(() => {
    master_data_get();
  }, []);

  const master_data_get = async () => {
    setshowLoaderAdmin(true);
    const form_data = new FormData();
    form_data.append("owner_staff_id", staff_id);
    form_data.append("default_restaurant_id", default_restaurant_id_staff);
    await server_post_data(get_last_resturant_data_save, form_data)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          if (Response.data.message.restaurant_data.length > 0) {
            setrestaurantData(Response.data.message.restaurant_data[0]);
            setPerDayMaximum(
              Response.data.message.restaurant_data[0].per_day_maximum
            );
            setApprovalPersonLimit(
              Response.data.message.restaurant_data[0].approval_person_limit
            );
            setBeforeBookingMin(
              Response.data.message.restaurant_data[0].before_booking_min
            );
            setTableTurnover(
              Response.data.message.restaurant_data[0].table_turnover
            );
            setSelectedOption(
              Response.data.message.restaurant_data[0].booking_slot_interval
            );
            setRestaurantPrice(
              Response.data.message.restaurant_data[0].restaurant_price
            );
          }
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="mt-3">
      <div className="containerRight">
        <div className="rightHeading">
          <div className="d-flex BackHeading">
            <button onClick={prevStep}>
              <img src={backButton} alt="Back Button" />
            </button>
            <h3>Restaurant Seat Availability / Price</h3>
          </div>
          <p>Add No. of seat availability on your restaurant</p>
        </div>
        <div className="overflowHeight">
          {[
            {
              label: "Per Day Maximum Seat*",
              placeholder: "Per Day Maximum Seat*",
              value: PerDayMaximum,
              setValue: setPerDayMaximum,
              focus: isFocused5,
              setFocus: setIsFocused5,
              tooltip:
                "Per Day Maximum Seating: The total number of seats that can be occupied in a restaurant in a single day.",
            },
            {
              label: "Approval Person Limit**",
              placeholder: "Approval Person Limit*",
              value: ApprovalPersonLimit,
              setValue: setApprovalPersonLimit,
              focus: isFocused4,
              setFocus: setIsFocused4,
              tooltip:
                "Approval Person Limit: The maximum number of people who can approve a particular action or decision.",
            },
            {
              label: "Booking Cut off Time*",
              placeholder: "Booking Cut off Time*",
              value: BeforeBookingMin,
              setValue: setBeforeBookingMin,
              focus: isFocused3,
              setFocus: setIsFocused3,
              tooltip:
                "Booking Cut-Off Time: The latest time by which customers can make a reservation.",
            },
            {
              label: "Table Turn Over Time**",
              placeholder: "Table Turn Over Time*",
              value: TableTurnover,
              setValue: setTableTurnover,
              focus: isFocused2,
              setFocus: setIsFocused2,
              tooltip:
                "Table turnover time:Start Time: When customers are seatedEnd Time: When the table is ready for new customers after they leave.]Total Time: End Time - Start Time.Keep track of these times to calculate table turnover",
            },
            {
              label: "Approx Average Price for Two person*",
              placeholder: "₹Approx Average Price for Two person*",
              value: RestaurantPrice,
              setValue: setRestaurantPrice,
              focus: isFocused,
              setFocus: setIsFocused,
              tooltip: "Average price for two people",
            },
          ].map(
            (
              { label, placeholder, value, setValue, focus, setFocus, tooltip },
              index
            ) => (
              <div
                key={index}
                className="col-sm-9 mb-3"
                style={{ position: "relative" }}
              >
                {focus && <label className="HeadBussiness">{label}</label>}
                <div className="col-10">
                  <div
                    className={`seattooltip ${focus ? "input-focused" : ""}`}
                  >
                    <input
                      type="text"
                      maxLength={3}
                      placeholder={placeholder}
                      className="form-control ActiveFormCOntrol"
                      value={value}
                      onChange={(e) => {
                        const trimmedMobile = e.target.value.trim();
                        const sanitizedMobile = trimmedMobile.replace(
                          /\D/g,
                          ""
                        );
                        setValue(sanitizedMobile);
                      }}
                      onFocus={() => setFocus(true)}
                      onBlur={() => setFocus(false)}
                    />
                    <img src={tooltipIcon} alt="tooltip" />
                    <div className="tooltiptext">{tooltip}</div>
                  </div>
                </div>
              </div>
            )
          )}

          <div className="col-sm-9 mb-1 mt-4">
            <label className="timeShort">
              Manage Reservation with Time slots of*
            </label>
          </div>
          <div className="col-sm-9 mb-3">
            <div className="ContainerCheckbox">
              {[15, 30].map((option) => (
                <div key={option} className="d-flex gap-1 timeSlots">
                  <input
                    type="radio"
                    checked={selectedOption === option}
                    onChange={() => handleRadioChange(option)}
                  />
                  <p className="m-0 p-0">{option} minutes</p>
                </div>
              ))}
            </div>
          </div>

          <div className="footerbtttn mt-4">
            {/* <div className="skipbutton" onClick={handleNext}>
              <button>Skip</button>
            </div> */}
            <div className="NextButton">
              <button onClick={handleNext} style={{ margin: 0 }}>
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Onboadingstep8;
