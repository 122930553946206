import React, { useState, useEffect } from "react";
import "./Css/ProfilePage.css";
import Header from "./Header";
import Mobilefotter from "./Mobilefotter.js";
import profile from "../Assets/userReviewProfilImg.svg";
import message from "../Assets/messageSvg.svg";
import phone from "../Assets/phoneSvg.svg";
import like from "../Assets/like.svg";
import likeRed from "../Assets/likeRed.svg";
import Edit from "../Assets/edit.svg";
import Delete from "../Assets/delete.svg";
import { Link } from "react-router-dom";
import { Rating } from "react-simple-star-rating";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import HeartRed from "../Assets/HeartRed.svg";
import contactus from "../Assets/averagePrice.svg";
import star from "../Assets/star.svg";
import calendar from "../Assets/calendarSearchBar.svg";
import Successs from "../Assets/checkone.webp";
import { PhoneInput } from "react-international-phone";
import { Modal, Button } from "react-bootstrap";
import OnBoardingTick from "../Assets/OnBoardingTick.svg";
import mainLogo from "../Assets/mainLogo.png";
import successPaymentGif from "../Assets/successPaymentGif.gif";
import {
  update_profile,
  get_profile,
  server_post_data,
  save_favourite,
  cancel_booking,
  update_notifiction_sms_status,
  APL_LINK,
  save_like,
  save_review,
} from "../ServiceConnection/serviceconnection.js";
import {
  removeData,
  retrieveData,
} from "../LocalConnection/LocalConnection.js";
import {
  combiled_form_data,
  handleAphabetsChange,
  handleError,
  handleLinkClick,
  make_image_from_letter,
  inputdateformateChange,
  formatTimeintotwodigit,
  handleEmailChange,
  handleIaphabetnumberChange,
  check_vaild_save,
  empty_form,
} from "../CommonJquery/CommonJquery.js";
let customer_id = "0";
let customer_name = "";
let customer_mobile_no = "";
let customer_email = "";
const ProfilePage = () => {
  customer_id = retrieveData("customer_id");
  customer_name = retrieveData("customer_name");
  customer_mobile_no = retrieveData("customer_mobile_no");
  customer_email = retrieveData("customer_email");
  const [getFavrate, setFavrate] = useState([]);
  const [GetRegistration, setRegistration] = useState([]);
  const [SEOloop, setSEOloop] = useState([]);
  const [activeTab, setActiveTab] = useState("upcomming");
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [editProfileData, seteditProfileData] = useState([]);
  const [userNumber, setUserNumber] = useState("");
  const [dob, setDob] = useState([]);
  const [formChanged, setFormChanged] = useState(false);
  const [ImageLink, setImageLink] = useState("");
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [showDltModal, setShowDltModal] = useState(false);
  const [reviewText, setReviewText] = useState("");
  const [ratings, setRatings] = useState([0, 0, 0, 0]);
  const [GetReviews, setGetReviews] = useState([]);
  const [HeartImg, setHeartImages] = useState([]);
  const [SelectedDataReview, setSelectedDataReview] = useState([]);

  useEffect(() => {
    if (customer_id === "0") {
      handleLinkClick("/");
    } else {
      master_data_get();
    }
  }, []);
  //get --Profile data
  const master_data_get = async () => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("flag_show", "0");
    await server_post_data(get_profile, fd)
      .then((Response) => {
        console.log(Response.data.message);
        if (Response.data.error) {
          // handleError(Response.data.message);
        } else {
          if (Response.data.message.guest_data.length > 0) {
            seteditProfileData(Response.data.message.guest_data[0]);
            setUserNumber(Response.data.message.guest_data[0].guest_mobile_no);
            setFavrate(Response.data.message.restro_data);
            setSEOloop(Response.data.message.data_seo);
            setGetReviews(Response.data.message.data_reviews);
            setImageLink(Response.data.message.image_link);
            setHeartImages(Response.data.message.like_data);
            if (Response.data.message.data_reservation.length > 0) {
              setRegistration(Response.data.message.data_reservation);
            }
            const ownerData = Response.data.message.guest_data[0];
            if (ownerData.guest_dob) {
              const dobArray = ownerData.guest_dob.split("-");
              setDob({
                day: parseInt(dobArray[2], 10),
                month: parseInt(dobArray[1], 10),
                year: parseInt(dobArray[0], 10),
              });
            }
          }
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };

  console.log(GetRegistration);

  const confirmVIP = () => {
    removeData();
  };
  //post profile or update profile
  const handleInputChange = (event) => {
    setFormChanged(true);
  };
  //post profile or update profile
  const openreviewModel = (data) => {
    setRatings([
      data.rating,
      data.location_rating,
      data.service_rating,
      data.ambience_rating,
      data.food_rating,
    ]);
    setSelectedDataReview(data);
    setShowReviewModal(true);
  };

  const opendeleteModel = (data) => {
    setRatings([
      data.rating,
      data.location_rating,
      data.service_rating,
      data.ambience_rating,
      data.food_rating,
    ]);
    setSelectedDataReview(data);
    setShowDltModal(true);
  };
  const handleSaveChangesdynamic = async (form_data, update_profile) => {
    let isValid = true;

    // Check first name
    const firstName = document.getElementById("fname").value.trim();
    if (!firstName) {
      document.getElementById("nameError").innerText =
        "Please enter the first name";
      isValid = false;
    } else {
      document.getElementById("nameError").innerText = "";
    }

    // Check last name
    const lastName = document.getElementById("lname").value.trim();
    if (!lastName) {
      document.getElementById("lnameError").innerText =
        "Please enter the last name";
      isValid = false;
    } else {
      document.getElementById("lnameError").innerText = "";
    }

    // Check email
    const email = document.getElementById("email").value.trim();
    if (!email) {
      document.getElementById("emailError").innerText =
        "Please enter the email";
      isValid = false;
    } else {
      document.getElementById("emailError").innerText = "";
    }

    // Check date of birth
    const day = document.getElementById("day").value.trim();
    const month = document.getElementById("month").value.trim();
    const year = document.getElementById("year").value.trim();
    if (!day || !month || !year) {
      document.getElementById("dobError").innerText =
        "Please enter the complete date of birth";
      isValid = false;
    } else {
      document.getElementById("dobError").innerText = "";
    }

    // Check gender
    const gender = document.querySelector('input[name="gender"]:checked');
    if (!gender) {
      document.getElementById("genderError").innerText =
        "Please select a gender";
      isValid = false;
    } else {
      document.getElementById("genderError").innerText = "";
    }

    if (!isValid) {
      // Scroll to the top to show the error messages
      window.scrollTo(0, 0);
      return;
    }

    // Proceed with form submission
    let fd_from = combiled_form_data(form_data, null);
    const dobString = `${year}-${month}-${day}`;
    fd_from.append("dob", dobString);
    fd_from.append("call_id", customer_id);

    try {
      setshowLoaderAdmin(true);
      const response = await server_post_data(update_profile, fd_from);
      setshowLoaderAdmin(false);
      if (response.data.error) {
        handleError(response.data.message);
      } else {
        handleOpenModal();
      }
    } catch (error) {
      console.error(error);
      setshowLoaderAdmin(false);
    }
  };

  const days = Array.from({ length: 31 }, (_, i) => i + 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 100 }, (_, i) => currentYear - i);
  //success modal
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  useEffect(() => {
    let timer;
    if (showModal) {
      timer = setTimeout(() => {
        setShowModal(false);
      }, 3000); // 3000ms = 3 seconds
    }
    return () => clearTimeout(timer);
  }, [showModal]);

  //download app qr modal
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [SelectedData, setSelectedData] = useState([]);

  const handleOpenCancelModal = (data) => {
    setSelectedData(data);
    setShowCancelModal(true);
  };
  const update_notifiction_sms_status_api = async (isChecked, flag_click) => {
    const click_yes_no = isChecked ? 1 : 0;
    const form_data = new FormData();
    form_data.append("flag_click", flag_click);
    form_data.append("click_yes_no", click_yes_no);
    setshowLoaderAdmin(true);
    await server_post_data(update_notifiction_sms_status, form_data)
      .then((Response) => {
        console.log(Response.data.message);
        if (Response.data.error) {
          handleError(Response.data.message);
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };

  const handleCheckboxChange = (event, click_type) => {
    const isChecked = event.target.checked;
    update_notifiction_sms_status_api(isChecked, click_type);
  };
  const cancelbooking = async (
    booking_id,
    restaurant_id,
    restaurant_country
  ) => {
    const form_data = new FormData();
    form_data.append("booking_id", booking_id);
    form_data.append("restaurant_id", restaurant_id);
    form_data.append("restaurant_country", restaurant_country);
    setshowLoaderAdmin(true);
    await server_post_data(cancel_booking, form_data)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          handleClose();
          handleLinkClick("");
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };

  const match_and_return_seo_link = (v_id) => {
    let data_seo_link_final = "/restro/restro_detail/" + v_id;
    let data_seo_link = data_seo_link_final;
    if (SEOloop) {
      const matchedItem = SEOloop.find((data) => {
        return data_seo_link === data.call_function_name;
      });

      if (matchedItem) {
        data_seo_link_final = matchedItem.pretty_function_name;
      }
    }
    return data_seo_link_final;
  };

  const like_save = async (primary_id, index, restaurant_id) => {
    // seterror_show("");
    const fd_from = new FormData();
    setshowLoaderAdmin(true);
    fd_from.append("review_id", primary_id);
    fd_from.append("restaurant_id", restaurant_id);
    await server_post_data(save_like, fd_from)
      .then((Response) => {
        setshowLoaderAdmin(false);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          const updatedFavorites = HeartImg.some(
            (fav) => fav.review_id === primary_id
          )
            ? HeartImg.filter((fav) => fav.review_id !== primary_id) // Remove from favorites
            : [...HeartImg, { review_id: primary_id }];
          setHeartImages(updatedFavorites);
        }
      })
      .catch((error) => {
        console.log(error);
        setshowLoaderAdmin(false);
      });
  };

  const handleHeartClick = (venueId) => {
    if (customer_id !== "0") {
      handleSaveChangesdynamicheart(venueId);
    } else {
      var event = new CustomEvent("customEvent");
      document.getElementById("login_check_jquery").dispatchEvent(event);
    }
  };
  const handleClose = () => {
    setShowDltModal(false);
    setShowReviewModal(false);
    setShowCancelModal(false);
  };

  const handleSaveChangesdynamicheart = async (venueId) => {
    const form_data = new FormData();
    form_data.append("venue_id", venueId);
    await server_post_data(save_favourite, form_data)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          handleLinkClick("");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSaveChangesdynamic_reviews = async (
    form_data,
    save_venueowner,
    flag
  ) => {
    let vaild_data = true;
    if (form_data !== null) {
      vaild_data = check_vaild_save(form_data);
    }

    if (vaild_data) {
      setshowLoaderAdmin(true);
      let fd_from = new FormData();
      if (form_data !== null) {
        fd_from = combiled_form_data(form_data, null);
        ratings.map((item, index) => {
          fd_from.append(`ratings${index}`, item);
        });
        fd_from.append("comment_show", reviewText);
        fd_from.append("customer_name", customer_name);
      }

      if (flag === 0) {
        if (SelectedDataReview.primary_id > 0) {
          fd_from.append("flag", 2);
        } else {
          fd_from.append("flag", 1);
        }
      } else {
        fd_from.append("flag", flag);
      }

      fd_from.append("restaurant_id", SelectedDataReview.restaurant_id);
      fd_from.append("reservation_id", SelectedDataReview.reservation_id);
      fd_from.append("review_id", SelectedDataReview.primary_id);
      await server_post_data(save_venueowner, fd_from)
        .then((Response) => {
          setshowLoaderAdmin(false);
          if (Response.data.error) {
            handleError(Response.data.message);
          } else {
            handleClose();
            if (form_data !== null) {
              empty_form(form_data);
            }
            master_data_get();
          }
        })
        .catch((error) => {
          setshowLoaderAdmin(false);
        });
    }
  };
  const handleRating = (rate, index) => {
    const newRatings = [...ratings];
    newRatings[index] = rate;
    setRatings(newRatings);
  };
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [isTabPanelVisible, setIsTabPanelVisible] = useState(true); // New state for visibility

  const handleTabClick = (index) => {
    setActiveTabIndex(index);
    setIsTabPanelVisible(true); // Show TabPanel when a tab is clicked
  };

  const check_login_or_not = (primary_id, index, restaurant_id) => {
    if (customer_id !== "0") {
      like_save(primary_id, index, restaurant_id);
    } else {
      var event = new CustomEvent("customEvent");
      document.getElementById("login_check_jquery").dispatchEvent(event);
    }
  };

  const isFavorite = (venueId) => {
    return HeartImg.some((fav) => fav.review_id === venueId);
  };
  return (
    <>
      <Header />
      <div className="profile_section">
        <div className="container-lg padding00">
          <section className="header_Section_profile_page row">
            <div className="profile_picture_section">
              <div className="person_name">
                <img
                  src={make_image_from_letter(customer_name)}
                  onError={(e) => {
                    e.target.src = mainLogo; // Provide the path to your fallback image
                  }}
                  alt={customer_name}
                />
              </div>{" "}
              <div className="profile_name_text_section">
                <h3>Hi, {customer_name}</h3>
                <span className="d-flex g-2 mb-2">
                  <img src={phone} alt="phone" />
                  <p> {customer_mobile_no}</p>
                </span>
                <span className="d-flex g-2">
                  <img src={message} alt="phone" />
                  <p> {customer_email}</p>
                </span>
              </div>
              <div></div>
            </div>
          </section>
          <section>
            <Tabs
              className="vertical-tabs vertical-tabsHor col-xl-10 col-lg-11 m-auto"
              selectedIndex={activeTabIndex}
              onSelect={handleTabClick}
            >
              <TabList className="tab-list tabListHow col-md-3 col-12">
                <Tab className="tab" selectedClassName="active-tab">
                  <h6>Reservation</h6>
                </Tab>
                <Tab className="tab" selectedClassName="active-tab">
                  <h6>Favourites</h6>
                </Tab>
                <Tab className="tab" selectedClassName="active-tab">
                  <h6>Reviews</h6>
                </Tab>
                <Tab className="tab" selectedClassName="active-tab">
                  <h6>Profile Details</h6>
                </Tab>
                <Tab className="tab" selectedClassName="active-tab">
                  <h6>Settings</h6>
                </Tab>
              </TabList>
              <Mobilefotter />
              <div className="col-md-9 col-12">
                {isTabPanelVisible && (
                  <TabPanel
                    className={`tab-panel ${
                      isTabPanelVisible && activeTabIndex === 0 ? "active" : ""
                    }`}
                  >
                    <section className="venue_about_section mt-md-3 mt-1">
                      <div className="tabs">
                        <div className="tab-buttonss reservations_section">
                          <button
                            className={activeTab === "about" ? "active" : ""}
                            onClick={() => setActiveTab("upcomming")}
                            style={{
                              color:
                                activeTab === "upcomming"
                                  ? "var(--primary-color)"
                                  : "black",
                              borderBottom:
                                activeTab === "upcomming"
                                  ? "1px solid var(--primary-color)"
                                  : "none",
                            }}
                          >
                            <h6>Upcoming</h6>
                          </button>
                          <button
                            className={activeTab === "menu" ? "active" : ""}
                            onClick={() => {
                              setActiveTab("past&cancel");
                            }}
                            style={{
                              color:
                                activeTab === "past&cancel"
                                  ? "var(--primary-color)"
                                  : "black",
                              borderBottom:
                                activeTab === "past&cancel"
                                  ? "1px solid var(--primary-color)"
                                  : "none",
                            }}
                          >
                            <h6>Past & Cancel</h6>
                          </button>
                        </div>
                        <hr width={"100%"} style={{ marginTop: "0" }} />
                        <div className="tab-content">
                          {activeTab === "upcomming" && (
                            <div>
                              <div className="container_venues_profile_page">
                                <h6 className="profile_page_upcomming_section_heading">
                                  Upcoming Reservations
                                </h6>
                                <div className="row">
                                  {GetRegistration &&
                                    GetRegistration.length > 0 &&
                                    GetRegistration.map(
                                      (venue, index) =>
                                        venue.type === "upcomming" && (
                                          <div
                                            key={index}
                                            className="col-lg-10 col-md-11 col-sm-10 margin24px"
                                          >
                                            <div className="row m-0 px-2 container_profile_section_venue">
                                              <div className="col-sm-4 px-0">
                                                <Link
                                                  onClick={() =>
                                                    handleLinkClick(
                                                      match_and_return_seo_link(
                                                        venue.data_res
                                                          .primary_id
                                                      )
                                                    )
                                                  }
                                                  style={{
                                                    textDecoration: "none",
                                                  }}
                                                >
                                                  <div className="venuePage_image_container ProfilePage_image_container">
                                                    {venue.data_res &&
                                                      venue.data_res
                                                        .restaurant_image && (
                                                        <img
                                                          src={
                                                            APL_LINK +
                                                            ImageLink +
                                                            venue.data_res
                                                              .restaurant_image
                                                          }
                                                          alt={
                                                            venue.data_res
                                                              .restaurant_name ||
                                                            "Venue Image"
                                                          }
                                                        />
                                                      )}
                                                  </div>
                                                </Link>
                                              </div>
                                              <div className="col-sm-8 p-0">
                                                <div className="venuePage_text_section ProfilePage_text_section">
                                                  <div className="venueContainer_rowtext">
                                                    <div className="venueContainer_nameAndAddress">
                                                      <h6>
                                                        {venue.data_res &&
                                                          venue.data_res
                                                            .restaurant_name}
                                                      </h6>
                                                    </div>
                                                  </div>
                                                  <span className="reservation_text">
                                                    <img
                                                      src={OnBoardingTick}
                                                      alt="OnBoardingTick"
                                                    />
                                                    <p>
                                                      {venue.booking_status ===
                                                      1
                                                        ? "Waiting for Confirmation"
                                                        : venue.booking_status ===
                                                          2
                                                        ? "Reservation Confirmed"
                                                        : venue.booking_status ===
                                                          3
                                                        ? "Reservation Complete"
                                                        : venue.booking_status ===
                                                          6
                                                        ? "Reservation Cancelled"
                                                        : ""}
                                                    </p>
                                                  </span>

                                                  <div className="venue_details_profile_page">
                                                    <span className="people_span">
                                                      <img
                                                        src={contactus}
                                                        alt="contactus"
                                                      />
                                                      <strong>
                                                        {venue.no_of_guest}
                                                      </strong>
                                                    </span>
                                                    <span className="people_span">
                                                      <img
                                                        src={calendar}
                                                        alt="calendar"
                                                      />
                                                      <strong>
                                                        {inputdateformateChange(
                                                          venue.book_date
                                                        )}{" "}
                                                        at{" "}
                                                        {formatTimeintotwodigit(
                                                          venue.book_time
                                                        )}
                                                      </strong>
                                                    </span>
                                                  </div>
                                                  <div className="venue_details_profile_page">
                                                    <span
                                                      className="people_span"
                                                      onClick={() => {
                                                        handleOpenCancelModal(
                                                          venue
                                                        );
                                                      }}
                                                    >
                                                      <strong>
                                                        <h6>Cancel</h6>
                                                      </strong>
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        )
                                    )}
                                </div>
                              </div>
                            </div>
                          )}
                          {activeTab === "past&cancel" && (
                            <div>
                              <div className="container_venues_profile_page">
                                <h6 className="profile_page_upcomming_section_heading">
                                  Past & Cancel Reservations
                                </h6>
                                <div className="row">
                                  {GetRegistration &&
                                    GetRegistration.length > 0 &&
                                    GetRegistration.map(
                                      (venue, index) =>
                                        venue.type === "past" && (
                                          <div
                                            key={index}
                                            className="col-12 margin24px"
                                          >
                                            <div className="row m-0 px-2 container_profile_section_venue">
                                              <div className="col-sm-4 px-0">
                                                <Link
                                                  onClick={() =>
                                                    handleLinkClick(
                                                      match_and_return_seo_link(
                                                        venue.data_res
                                                          .primary_id
                                                      )
                                                    )
                                                  }
                                                  style={{
                                                    textDecoration: "none",
                                                  }}
                                                >
                                                  <div className="venuePage_image_container ProfilePage_image_container">
                                                    {venue.data_res &&
                                                      venue.data_res
                                                        .restaurant_image && (
                                                        <img
                                                          src={
                                                            APL_LINK +
                                                            ImageLink +
                                                            venue.data_res
                                                              .restaurant_image
                                                          }
                                                          alt={
                                                            venue.data_res
                                                              .restaurant_name ||
                                                            "Venue Image"
                                                          }
                                                        />
                                                      )}
                                                  </div>
                                                </Link>
                                              </div>
                                              <div className="col-sm-8 paddingLeft1">
                                                <div className="venuePage_text_section ProfilePage_text_section">
                                                  <div className="venueContainer_rowtext">
                                                    <div className="venueContainer_nameAndAddress">
                                                      <h6>
                                                        {venue.data_res &&
                                                          venue.data_res
                                                            .restaurant_name}
                                                      </h6>
                                                    </div>
                                                  </div>
                                                  <span className="reservation_text">
                                                    <img
                                                      src={OnBoardingTick}
                                                      alt="OnBoardingTick"
                                                    />
                                                    <p>
                                                      {venue.booking_status ===
                                                      1
                                                        ? "Waiting for Confirmation"
                                                        : venue.booking_status ===
                                                          2
                                                        ? "Reservation Confirmed"
                                                        : venue.booking_status ===
                                                          3
                                                        ? "Reservation Complete"
                                                        : venue.booking_status ===
                                                          6
                                                        ? "Reservation Cancelled"
                                                        : ""}
                                                    </p>
                                                  </span>

                                                  <div className="venue_details_profile_page">
                                                    <span className="people_span">
                                                      <img
                                                        src={contactus}
                                                        alt="contactus"
                                                      />
                                                      <strong>
                                                        {venue.no_of_guest}
                                                      </strong>
                                                    </span>
                                                    <span className="people_span">
                                                      <img
                                                        src={calendar}
                                                        alt="calendar"
                                                      />
                                                      <strong>
                                                        {inputdateformateChange(
                                                          venue.book_date
                                                        )}{" "}
                                                        at{" "}
                                                        {formatTimeintotwodigit(
                                                          venue.book_time
                                                        )}
                                                      </strong>
                                                    </span>
                                                  </div>
                                                  {parseInt(
                                                    venue.data_review_id
                                                  ) === 0 &&
                                                    venue.booking_status ===
                                                      3 && (
                                                      <button
                                                        onClick={() =>
                                                          openreviewModel({
                                                            primary_id: 0,
                                                            entry_date: null,
                                                            restaurant_id:
                                                              venue.restaurant_id,
                                                            restaurant_name:
                                                              venue.data_res &&
                                                              venue.data_res
                                                                .restaurant_name,
                                                            reservation_id:
                                                              venue.primary_id,
                                                            customer_id:
                                                              venue.guest_id,
                                                            customer_name:
                                                              venue.guest_name,
                                                            customer_image:
                                                              null,
                                                            rating: 0,
                                                            location_rating: 0,
                                                            service_rating: 0,
                                                            ambience_rating: 0,
                                                            food_rating: 0,
                                                            total_review: 0,
                                                            comment: "",
                                                            total_like: 0,
                                                            flag: "c",
                                                          })
                                                        } // Add your click handler function here
                                                        style={{
                                                          marginLeft: "10px",
                                                          padding:
                                                            "0.3rem 1rem",
                                                          background:
                                                            "transparent",
                                                          borderRadius: "10px",
                                                          width: "150px",
                                                        }} // Add margin to separate button from text
                                                      >
                                                        Add Review
                                                      </button>
                                                    )}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        )
                                    )}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </section>
                  </TabPanel>
                )}
                <TabPanel
                  className={`tab-panel ${
                    isTabPanelVisible && activeTabIndex === 1 ? "active" : ""
                  }`}
                >
                  <div className="favourite_section2">
                    <h6>My Favorite Restaurant</h6>
                    <div className="container">
                      {getFavrate &&
                        getFavrate.length > 0 &&
                        getFavrate.map((venue, index) => (
                          <div className="fevorateContanrr" key={index}>
                            <div
                              key={index}
                              className="col-xl-12 col-12 margin24px"
                              style={{ overflow: "hidden" }}
                            >
                              <div className="VenuePage_venue_container">
                                <div className="row m-0">
                                  <div className="col-sm-5 px-0">
                                    <Link
                                      onClick={() => {
                                        handleLinkClick(
                                          match_and_return_seo_link(
                                            venue.primary_id
                                          )
                                        );
                                      }}
                                      style={{ textDecoration: "none" }}
                                    >
                                      <div className="venuePage_image_container">
                                        <img
                                          src={
                                            APL_LINK +
                                            ImageLink +
                                            venue.restaurant_image
                                          }
                                          alt={venue.restaurant_name}
                                        />
                                        <div className="venuePage_ratingSection">
                                          <p>{venue.rating || "N/A"}</p>
                                          <img src={star} alt="star" />
                                        </div>
                                      </div>
                                    </Link>
                                  </div>
                                  <div className="col-sm-7">
                                    <div className="venuePage_text_section">
                                      <div className="venueContainer_rowtext">
                                        <div className="venueContainer_nameAndAddress">
                                          <h6>
                                            {venue.restaurant_name || "No Name"}
                                          </h6>
                                        </div>
                                        <div className="heart_section">
                                          <img
                                            src={HeartRed}
                                            alt="Heart"
                                            className="heart_icon favHeartIcon"
                                            onClick={() =>
                                              handleHeartClick(venue.primary_id)
                                            }
                                          />
                                        </div>
                                      </div>
                                      <p>
                                        {venue.restaurant_full_adrress ||
                                          "No  add"}
                                      </p>

                                      <h6 className="avrgPrice">
                                        <img
                                          src={contactus}
                                          alt="contactus"
                                          width={15}
                                        />
                                        Average Price {venue.first_money_icon}{" "}
                                        {venue.restaurant_price}{" "}
                                        {venue.last_money_icon}
                                      </h6>
                                      <span className="venuePage_venue_category_titles marginNone">
                                        {venue.amenities?.map(
                                          (amenity, amenityIndex) => (
                                            <div
                                              key={amenityIndex}
                                              className="amenityItem"
                                            >
                                              <img
                                                src={`${APL_LINK}/assets/${amenity.image}`}
                                                alt={amenity.amenities_name}
                                              />
                                              <label>
                                                {amenity.amenities_name}
                                              </label>
                                            </div>
                                          )
                                        )}
                                      </span>

                                      <div className="TimingButtons2">
                                        {venue.timing?.map(
                                          (timeshow, amenityIndex) => (
                                            <div
                                              className="timesBtns"
                                              key={amenityIndex}
                                            >
                                              <p>
                                                {" "}
                                                {formatTimeintotwodigit(
                                                  timeshow.start_time
                                                )}
                                              </p>
                                              <div className="childtime">
                                                {timeshow.per_discount > 0 ? (
                                                  <p>
                                                    -{timeshow.per_discount}%
                                                  </p>
                                                ) : (
                                                  <p>N/A</p>
                                                )}
                                              </div>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}

                      {showLoaderAdmin && <p>Loading...</p>}
                    </div>
                  </div>
                </TabPanel>
                <TabPanel
                  className={`tab-panel ${
                    isTabPanelVisible && activeTabIndex === 2 ? "active" : ""
                  }`}
                >
                  <div>
                    <section className="container-lg">
                      {GetReviews.map((review, index) => (
                        <div className="user_review_container" key={index}>
                          <div className="user_review_wrapper">
                            <div>
                              <img src={profile} alt="profile" />
                            </div>
                            <div className="user_review_rowcontainer">
                              <div className="">
                                <div className="user_review_rowcontainer_name">
                                  <h6>
                                    {review.data_res &&
                                      review.data_res.restaurant_name}
                                  </h6>
                                </div>
                                <div className="user_review_rowcontainer_date">
                                  {inputdateformateChange(review.entry_date)}
                                </div>
                              </div>
                              <div>{review.rating}/5.0</div>
                            </div>
                          </div>
                          <div className="user_review_description">
                            <p>{review.comment}</p>
                          </div>
                          <div className="user_review_like_report_section">
                            <span className="user_review_like">
                              <button
                                className="d-flex align-items-center"
                                style={{
                                  border: "none",
                                  background: "transparent",
                                }}
                                onClick={() =>
                                  check_login_or_not(
                                    review.primary_id,
                                    index,
                                    review.restaurant_id
                                  )
                                }
                                type="button"
                              >
                                {isFavorite(review.primary_id) ? (
                                  <>
                                    <img
                                      src={likeRed}
                                      alt="likeRed"
                                      className="LikeImgg"
                                    />
                                    <p style={{ marginLeft: "4px" }}>Liked</p>
                                  </>
                                ) : (
                                  <>
                                    <img src={like} alt="like" />
                                    <p style={{ marginLeft: "4px" }}>Like</p>
                                  </>
                                )}
                              </button>
                              <button
                                className="d-flex align-items-center"
                                style={{
                                  border: "none",
                                  background: "transparent",
                                }}
                                type="button"
                                onClick={() => openreviewModel(review)}
                              >
                                <img
                                  src={Edit}
                                  alt="like"
                                  className="LikeImgg"
                                />
                                <p style={{ marginLeft: "4px" }}>Edit</p>
                              </button>
                              <button
                                className="d-flex align-items-center"
                                style={{
                                  border: "none",
                                  background: "transparent",
                                }}
                                type="button"
                                onClick={() => opendeleteModel(review)}
                              >
                                <img
                                  src={Delete}
                                  alt="like"
                                  className="LikeImgg"
                                />
                                <p style={{ marginLeft: "4px" }}>Delete</p>
                              </button>
                            </span>
                          </div>
                          <hr />
                        </div>
                      ))}
                    </section>
                  </div>
                </TabPanel>
                <TabPanel
                  className={`tab-panel ${
                    isTabPanelVisible && activeTabIndex === 3 ? "active" : ""
                  }`}
                >
                  <div>
                    <section className="container-lg">
                      <div className="row">
                        <div className="col-md-12 padding00">
                          <div className="ProfileCont">
                            <div className="profile_section">
                              <div>
                                <div className="register-venue-form-heading">
                                  <h2>My Profile</h2>
                                  <p>Manage my personal Information </p>
                                  <desc>
                                    Your Contact information will be send to the
                                    Restaurant Owner when you make a Enquiry
                                  </desc>
                                </div>
                                <form
                                  className="venue-registration-form profile_pafe_form"
                                  id="UpateProfile"
                                >
                                  <div className="row">
                                    <div className="col-md-6">
                                      <label htmlFor="venueName">
                                        First Name
                                      </label>
                                      <input
                                        type="text"
                                        id="fname"
                                        name="fname"
                                        className="form-control"
                                        defaultValue={
                                          editProfileData.guest_fname || ""
                                        }
                                        onInput={handleAphabetsChange}
                                        onChange={handleInputChange}
                                      />
                                      <span
                                        id="nameError"
                                        className="error-message"
                                      ></span>
                                    </div>
                                    <div className="col-md-6">
                                      <label htmlFor="venueLocation">
                                        Last Name
                                      </label>
                                      <input
                                        type="text"
                                        id="lname"
                                        name="lname"
                                        className="form-control "
                                        onInput={handleAphabetsChange}
                                        defaultValue={
                                          editProfileData.guest_lname || ""
                                        }
                                        onChange={handleInputChange}
                                      />
                                      <span
                                        id="lnameError"
                                        className="error-message"
                                      ></span>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-6">
                                      <label htmlFor="contactPerson">
                                        Email
                                      </label>
                                      <input
                                        type="text"
                                        id="email"
                                        name="email"
                                        onInput={handleEmailChange}
                                        className="form-control"
                                        defaultValue={
                                          editProfileData.guest_email || ""
                                        }
                                        onChange={handleInputChange}
                                      />
                                      <span
                                        id="emailError"
                                        className="error-message"
                                      ></span>
                                    </div>
                                    <div className="col-md-6 birth_date_profile">
                                      <label className="mb-2">
                                        Date of Birth
                                      </label>
                                      <div className="DOBCalander">
                                        <select
                                          id="day"
                                          name="day"
                                          className="form-control  custom-select"
                                          defaultValue={dob.day || ""}
                                          onChange={handleInputChange}
                                        >
                                          {days.map((day) => (
                                            <option key={day} value={day}>
                                              {day}
                                            </option>
                                          ))}
                                        </select>
                                        <select
                                          id="month"
                                          name="month"
                                          className="form-control  custom-select mr-2"
                                          defaultValue={dob.month || ""}
                                          onChange={handleInputChange}
                                        >
                                          {months.map((month, index) => (
                                            <option
                                              key={month}
                                              value={index + 1}
                                            >
                                              {month}
                                            </option>
                                          ))}
                                        </select>
                                        <select
                                          id="year"
                                          name="year"
                                          className="form-control  custom-select"
                                          defaultValue={dob.year || ""}
                                          onChange={handleInputChange}
                                        >
                                          {years.map((year) => (
                                            <option key={year} value={year}>
                                              {year}
                                            </option>
                                          ))}
                                        </select>
                                        <span
                                          id="dobError"
                                          className="error-message"
                                        ></span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-6">
                                      <label htmlFor="phone">Phone</label>
                                      <PhoneInput
                                        id="phoneNumberProfilePage"
                                        placeholder="Phone Number"
                                        className="form-control mt-2"
                                        defaultCountry="in"
                                        value={userNumber}
                                        onChange={(phone) => {
                                          setUserNumber(phone);
                                          handleInputChange();
                                        }}
                                        name="phone"
                                        disabled
                                      />
                                    </div>
                                    <div className="col-md-6">
                                      <label htmlFor="phone">Gender</label>
                                      <span className="radio_buttons_reg_form mt-2 ">
                                        <input
                                          type="radio"
                                          id="1"
                                          name="gender"
                                          value="Male"
                                          defaultChecked={
                                            editProfileData.guest_gender ===
                                              "Male" || ""
                                          }
                                          onChange={handleInputChange}
                                        />
                                        <label>Male</label>
                                        <br />
                                        <input
                                          type="radio"
                                          id="2"
                                          name="gender"
                                          value="Female"
                                          defaultChecked={
                                            editProfileData.guest_gender ===
                                              "Female" || ""
                                          }
                                          onChange={handleInputChange}
                                        />
                                        <label>Female</label>
                                        <br />
                                        <input
                                          type="radio"
                                          id="3"
                                          name="gender"
                                          value="Others"
                                          defaultChecked={
                                            editProfileData.guest_gender ===
                                              "Others" || ""
                                          }
                                          onChange={handleInputChange}
                                        />
                                        <label>Others</label>
                                      </span>
                                      <span
                                        id="genderError"
                                        className="error-message"
                                      ></span>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div
                                      className="col-md-12 checkBox_registerMyVenue"
                                      style={{ justifyContent: "end" }}
                                    >
                                      <br />
                                      <button
                                        className="SubmttBUTTTOnn"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          handleSaveChangesdynamic(
                                            "UpateProfile",
                                            update_profile
                                          );
                                        }}
                                        type="submit"
                                        style={{
                                          opacity: formChanged ? 1 : 0.5,
                                        }}
                                        disabled={!formChanged}
                                      >
                                        Submit
                                      </button>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>

                    {/* <Modal
                      show={showModal}
                      onHide={() => setShowModal(false)}
                      centered
                      className="success_modal_reg "
                    >
                      <Modal.Body>
                        <div className="success_modal_register_my_venue ">
                          <img src={Successs} alt="success" />
                          <h3>Your profile have been updated successfully !</h3>
                        </div>
                      </Modal.Body>
                    </Modal> */}

                    <Modal
                      show={showModal}
                      onHide={() => setShowModal(false)}
                      className="text-center submtformmdl"
                      centered
                    >
                      <Modal.Body className="bg-gray-100 p-6">
                        <div className="succSSimg">
                          <img
                            src={successPaymentGif}
                            alt="successPaymentGif"
                          />
                        </div>
                        <h4 className="succMsg">Profile Updated</h4>

                        <div className="flex flex-col items-center">
                          <p className="text-lg font-semibold Scndhead">
                            Your profile has been successfully updated!
                          </p>
                          <p className="text-gray-600 thirdhead">
                            Thank you for keeping your information up to date.
                          </p>
                        </div>
                      </Modal.Body>
                    </Modal>
                  </div>
                </TabPanel>
                <TabPanel
                  className={`tab-panel ${
                    isTabPanelVisible && activeTabIndex === 4 ? "active" : ""
                  }`}
                >
                  <div className="settings_section_profile_page padding00">
                    <h6>Settings</h6>
                    <div className="settings_text_profile_page">
                      <h5>Manage My Notification </h5>
                      <p>Newsletter</p>
                      <div className="toggle_switches_settings">
                        <span className="switch_case">
                          <label className="switch marginTop0">
                            <input
                              type="checkbox"
                              onChange={(event) =>
                                handleCheckboxChange(event, "1")
                              }
                              defaultChecked={
                                editProfileData.sms_come_dfoodo === 1
                                  ? true
                                  : false
                              }
                            />
                            <span className="slider round"></span>
                          </label>
                          <p>
                            I agree to receive offers and updates from the
                            Dfoodo by SMS
                          </p>
                        </span>
                        <span className="switch_case">
                          <label className="switch marginTop0">
                            <input
                              type="checkbox"
                              onChange={(event) =>
                                handleCheckboxChange(event, "2")
                              }
                              defaultChecked={
                                editProfileData.email_come_dfoodo === 1
                                  ? true
                                  : false
                              }
                            />
                            <span className="slider round"></span>
                          </label>
                          <p>
                            {" "}
                            I agree to receive email offers and Communications
                            from DFOODO{" "}
                          </p>
                        </span>
                      </div>
                      <button
                        className="loginButton mt-3"
                        onClick={() => confirmVIP()}
                      >
                        Logout
                      </button>
                    </div>
                  </div>
                </TabPanel>
              </div>
            </Tabs>
          </section>
        </div>
      </div>

      <Modal
        show={showCancelModal}
        onHide={() => handleClose()}
        centered
        className="modal-md"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="">
            <div className="cancel_modal_style">
              <h5>Are you sure you want to cancel this reservation?</h5>
              <h6>
                {SelectedData.data_res && SelectedData.data_res.restaurant_name}
              </h6>
              <div className="venue_details_profile_page">
                <span className="people_span">
                  <img src={contactus} alt="contactus" />
                  <strong>{SelectedData.no_of_guest}</strong>
                </span>
                <span className="people_span">
                  <img src={calendar} alt="calendar" />
                  <strong>
                    {inputdateformateChange(SelectedData.book_date)} at{" "}
                    {formatTimeintotwodigit(SelectedData.book_time)}
                  </strong>
                </span>
              </div>
              <span className="buttons_cancel_modal">
                <button onClick={() => handleClose()}>Never mind</button>
                <button
                  onClick={() =>
                    cancelbooking(
                      SelectedData.primary_id,
                      SelectedData.restaurant_id,
                      SelectedData.data_res.restaurant_country
                    )
                  }
                >
                  Confirm Cancellation
                </button>
              </span>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* {Review Modal} */}
      <Modal
        show={showReviewModal}
        onHide={() => setShowReviewModal(false)}
        centered
      >
        <Modal.Body>
          {SelectedDataReview.primary_id > -1 && (
            <>
              <div className="user_detail_section">
                <div className="user_image">
                  <img src={profile} alt="profile" />
                </div>
                <div className="user_details">
                  <h6>
                    {console.log(SelectedDataReview)}
                    {SelectedDataReview.restaurant_name}
                    {SelectedDataReview.data_res &&
                      SelectedDataReview.data_res.restaurant_name}
                  </h6>
                  <p>Posting Publicly </p>
                </div>
              </div>
              <form
                className="venue-registration-form"
                id="vanueregistrationsdfbjsbfjbfjsdf"
              >
                <div className="stars_rating_section">
                  {["Overall", "Location ", "Service", "Ambience", "Food"].map(
                    (label, index) => (
                      <div key={index} className="mb-3 stars_text_wrapper">
                        <label>{label}</label>
                        <Rating
                          onClick={(rate) => handleRating(rate, index)}
                          ratingValue={ratings[index]}
                          size={30}
                          label
                          transition
                          fillColor="var(--primary-color)"
                          emptyColor="gray"
                        />
                      </div>
                    )
                  )}
                </div>
                <div className="mb-3">
                  <textarea
                    rows={3}
                    maxLength={300}
                    id="comment"
                    name="comment"
                    defaultValue={SelectedDataReview.comment || ""}
                    onChange={(e) => setReviewText(e.target.value)}
                    className="form-control trio_mandatory"
                    placeholder="Share details of your own experience at this place"
                    onInput={handleIaphabetnumberChange}
                  />
                </div>
              </form>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => handleClose()}
            style={{ backgroundColor: "grey" }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() =>
              handleSaveChangesdynamic_reviews(
                "vanueregistrationsdfbjsbfjbfjsdf",
                save_review,
                0
              )
            }
            style={{
              backgroundColor: "var(--primary-color)",
              outline: "none",
              border: "none",
              color: "var(--white)",
              padding: "0.43rem 1rem",
            }}
          >
            {SelectedData.primary_id !== 0 &&
            SelectedData.primary_id !== undefined
              ? "Update" + SelectedData.primary_id
              : "Save"}{" "}
            {/* Corrected comparison */}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showDltModal}
        onHide={() => setShowDltModal(false)}
        centered
        className="modal-md"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="cancel_modal_style m-0">
            <h5>Are you sure you want to Delete this review?</h5>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => handleClose()}
            style={{ backgroundColor: "grey" }}
          >
            Never mind
          </Button>
          <Button
            variant="primary"
            onClick={() =>
              handleSaveChangesdynamic_reviews(null, save_review, 3)
            }
            style={{
              backgroundColor: "var(--primary-color)",
              outline: "none",
              border: "none",
              color: "var(--white)",
              padding: "0.43rem 1rem",
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ProfilePage;
