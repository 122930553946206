import axios from "axios";
import { retrieveData } from "../LocalConnection/LocalConnection.js";
const appauth_key = "dfoodo@2029";
let APL_LINK = "http://192.168.1.7:8000/";
APL_LINK = "https://dfoodobackend.dfoodo.com/";
let Website_URL = "https://www.dfoodo.com/";
let local_server_link_react = APL_LINK + "api/web_link/";
let local_server_link_back = APL_LINK + "api/admin_link/";
let local_server_link_super = APL_LINK + "api/super_admin_link/";

const fssai_verification_combined =
  local_server_link_back + "fssai_verification_combined";
const gst_verification_combined =
  local_server_link_back + "gst_verification_combined";
const view_week_all_timing = local_server_link_back + "view_week_all_timing";
const save_update_week_timing =
  local_server_link_back + "save_update_week_timing";
const staff_login = local_server_link_back + "staff_login";
const get_last_resturant_data_save =
  local_server_link_back + "get_last_resturant_data_save";
const sava_onboarding_form = local_server_link_back + "sava_onboarding_form";
const sava_onboarding_form_otp =
  local_server_link_back + "sava_onboarding_form_otp";
const delete_resturant_images_menu =
  local_server_link_super + "delete_resturant_images_menu";
const get_blog_data_website =
  local_server_link_react + "get_blog_data_website/";
const blog_details_website = local_server_link_react + "blog_details_website/";
const get_categorypage_webapp =
  local_server_link_react + "get_categorypage_webapp/";
const get_all_faq_website = local_server_link_react + "get_all_faq_website/";
const save_restaurantOwnerdetails =
  local_server_link_react + "save_restaurantOwnerdetails/";
const save_favourite = local_server_link_react + "save_favourite/";
const update_profile = local_server_link_react + "update_profile/";
const get_profile = local_server_link_react + "get_profile/";
const get_restropage_webapp =
  local_server_link_react + "get_restropage_webapp/";
const get_landingpage_webapp =
  local_server_link_react + "get_landingpage_webapp/";

const get_all_timing_date_wise =
  local_server_link_back + "get_all_timing_date_wise";

const city_list = local_server_link_react + "city_list/";
const get_terms_privacy_data =
  local_server_link_react + "get_terms_privacy_data/";
const get_favourite = local_server_link_react + "get_favourite/";
const get_reservation_webapp =
  local_server_link_react + "get_reservation_webapp/";

const create_table_reservation_website =
  local_server_link_react + "create_table_reservation_website";
const customer_login = local_server_link_react + "guest_login/";
const save_like = local_server_link_react + "save_like/";
const save_review = local_server_link_react + "save_review/";
const update_notifiction_sms_status =
  local_server_link_react + "update_notifiction_sms_status/";
const cancel_booking = local_server_link_react + "cancel_booking/";
const get_seo_data_website = local_server_link_react + "get_seo_data_website/";
const get_search_bar = local_server_link_react + "get_search_bar/";
const get_review = local_server_link_react + "get_review/";

const server_post_data = async (url_for, form_data) => {
  const customer_id = retrieveData("customer_id");
  let country_name_take = retrieveData("customer_country");
  const country_name = country_name_take === "0" ? "Sweden" : country_name_take;
  let city_name_take = retrieveData("customer_city");
  const city_name = city_name_take === "0" ? "Gothenburg" : city_name_take;
  const city_name_take_lat = retrieveData("customer_city_lat");
  const city_name_take_long = retrieveData("customer_city_long");
  // const headers = {
  //   "Content-Type": "application/json",
  // };
  if (form_data === null) {
    form_data = new FormData();
  }
  form_data.append("country", country_name);
  form_data.append("city", city_name);
  form_data.append("city_lat", city_name_take_lat);
  form_data.append("city_long", city_name_take_long);
  form_data.append("appauth_key", appauth_key);
  form_data.append("customer_id", customer_id);
  form_data.append("booking_from", "website");
  return axios.post(url_for, form_data);
};

export {
  APL_LINK,
  Website_URL,
  appauth_key,
  server_post_data,
  get_blog_data_website,
  blog_details_website,
  get_all_faq_website,
  save_restaurantOwnerdetails,
  update_profile,
  get_landingpage_webapp,
  get_restropage_webapp,
  get_categorypage_webapp,
  get_profile,
  save_favourite,
  get_all_timing_date_wise,
  create_table_reservation_website,
  customer_login,
  save_review,
  save_like,
  cancel_booking,
  update_notifiction_sms_status,
  get_seo_data_website,
  city_list,
  get_favourite,
  get_search_bar,
  get_terms_privacy_data,
  get_reservation_webapp,
  get_review,
  view_week_all_timing,
  save_update_week_timing,
  staff_login,
  sava_onboarding_form,
  get_last_resturant_data_save,
  delete_resturant_images_menu,
  sava_onboarding_form_otp,
  fssai_verification_combined,
  gst_verification_combined,
};
