import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { nextStep, prevStep } from "./stepSlice";
import HeaderOnboading from "./HeaderOnboading";
import Onboadingstep1 from "./Onboadingstep1";
import Onboadingstep2 from "./Onboadingstep2";
import Onboadingstep3 from "./Onboadingstep3";
import Onboadingstep4 from "./Onboadingstep4";
import Onboadingstep5 from "./Onboadingstep5";
import Onboadingstep6 from "./Onboadingstep6";
import Onboadingstep7 from "./Onboadingstep7";
import Onboadingstep8 from "./Onboadingstep8";
import Onboadingstep9 from "./Onboadingstep9";
import Onboadingstep10 from "./Onboadingstep10";
import Onboadingstep11 from "./Onboadingstep11";
import Onboadingstep12 from "./Onboadingstep12";
import Onboadingstep13 from "./Onboadingstep13";
import "./Css/Newonboading.css";
import step0 from "../Assets/step0.png";
import step1 from "../Assets/step1.png";
import step2 from "../Assets/step2.png";
import step3 from "../Assets/step3.png";
import step4 from "../Assets/step4.png";
import step5 from "../Assets/step5.png";
import step6 from "../Assets/step6.png";
import step7 from "../Assets/step7.png";
import step8 from "../Assets/step8.png";
import step9 from "../Assets/step9.png";
import step10 from "../Assets/step10.png";
import step11 from "../Assets/step11.png";
import step12 from "../Assets/step12.png";
import step13 from "../Assets/step13.png";
import { retrieveData, storeData } from "../LocalConnection/LocalConnection";
import {
  handleError,
  handleAphabetsChange,
} from "../CommonJquery/CommonJquery";
import {
  sava_onboarding_form,
  get_last_resturant_data_save,
  server_post_data,
} from "../ServiceConnection/serviceconnection";
import Onboadingstep14 from "./onboardingstep14";
let staff_id = "0";
let default_restaurant_id_staff = "0";
const NewOnboading = () => {
  staff_id = retrieveData("staff_id");
  default_restaurant_id_staff = retrieveData("default_restaurant_id_staff");
  const [currentStep, setcurrentStep] = useState(
    useSelector((state) => state.currentStep)
  );
  // let currentStep = 14;

  const dispatch = useDispatch();
  const totalSteps = 13;
  const [restaurantName, setRestaurantName] = useState("");
  const [restaurantNameError, setRestaurantNameError] = useState(false);
  const [restaurantData, setrestaurantData] = useState([]);
  const [shake, setShake] = useState(false);
  const [isRestaurantNameFocused, setIsRestaurantNameFocused] = useState(false);
  const [isAdditionalInfoFocused, setIsAdditionalInfoFocused] = useState(false);
  const [isRestaurantDescriptionFocused, setIsRestaurantDescriptionFocused] =
    useState(false);

  const [restoDistribution, setRestoDistribution] = useState("");
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [showLoader, setshowLoader] = useState(false);
  const [progressPercentage, setProgressPercentage] = useState(0);

  useEffect(() => {
    const progress = (currentStep / totalSteps) * 100;
    setProgressPercentage(progress);

    const interval = setInterval(() => {
      setProgressPercentage((prev) => {
        if (prev >= progress) {
          clearInterval(interval);
          return progress;
        }
        return prev + 1;
      });
    }, 100);

    // Cleanup the interval when component unmounts
    return () => clearInterval(interval);
  }, [currentStep, totalSteps]);
  const handleNextStep = () => {
    if (restaurantName.trim() === "") {
      setRestaurantNameError(true);
      setShake(true);
      setTimeout(() => setShake(false), 500);
    } else {
      if (staff_id !== "0") {
        handleSaveChangesdynamic();
      } else {
        var event = new CustomEvent("customEvent");
        document
          .getElementById("login_check_jquery_staff")
          .dispatchEvent(event);
      }
    }
  };

  const handleInputChange = (e) => {
    setRestaurantName(e.target.value);
    if (e.target.value.trim() !== "") {
      setRestaurantNameError(false);
      setShake(false);
    }
  };

  const handleAdditionalInfoChange = (e) => {
    setAdditionalInfo(e.target.value);
  };
  const handleRestoDiscribtionChange = (e) => {
    setRestoDistribution(e.target.value);
    setIsRestaurantDescriptionFocused(e.target.value);
  };

  const handleSaveChangesdynamic = async () => {
    setshowLoader(true);
    const form_data = new FormData();
    form_data.append("owner_staff_id", staff_id);
    form_data.append("default_restaurant_id", default_restaurant_id_staff);
    form_data.append("restaurant_name", restaurantName);
    form_data.append("restaurant_tagline", additionalInfo);
    form_data.append("restaurant_about", restoDistribution);
    form_data.append("step_save", "0");

    await server_post_data(sava_onboarding_form, form_data)
      .then(async (Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          storeData(
            "default_restaurant_id_staff",
            Response.data.default_restaurant_id
          );
          setRestaurantNameError(false);
          master_data_get();
          dispatch(nextStep());
        }
        setshowLoader(false);
      })
      .catch((error) => {
        setshowLoader(false);
        console.log(error);
      });
  };

  useEffect(() => {
    master_data_get();
  }, []);

  const master_data_get = async () => {
    setshowLoaderAdmin(true);
    const form_data = new FormData();
    form_data.append("owner_staff_id", staff_id);
    form_data.append("default_restaurant_id", default_restaurant_id_staff);
    await server_post_data(get_last_resturant_data_save, form_data)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          if (Response.data.message.restaurant_data.length > 0) {
            setrestaurantData(Response.data.message.restaurant_data[0]);
            setRestaurantName(
              Response.data.message.restaurant_data[0].restaurant_name
            );
            setAdditionalInfo(
              Response.data.message.restaurant_data[0].restaurant_tagline
            );
            setcurrentStep(
              Response.data.message.restaurant_data[0].restaurant_step_save
            );
            setRestoDistribution(
              Response.data.message.restaurant_data[0].restaurant_about
            );
          }
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    setcurrentStep(currentStep);
  }, [currentStep]);

  const handlePrevStep = () => {
    dispatch(prevStep());
    setcurrentStep((prev) => (prev > 0 ? prev - 1 : 0));
  };
  const handlePrevStepcustom = (click_type) => {
    dispatch(prevStep());
    setcurrentStep(click_type);
  };
  return (
    <div style={{ overflow: "hidden", height: "100vh" }}>
      {showLoaderAdmin ? (
        <HeaderOnboading />
      ) : (
        <div>
          <HeaderOnboading />
          {currentStep !== 14 && (
            <div
              className={` my-4 ${
                currentStep === 9 ? "col-md-11 mx-auto" : "container-lg"
              } h-100`}
            >
              <div className="row">
                {currentStep < 12 && (
                  <div
                    className={` ${
                      currentStep === 9
                        ? "col-md-4 mx-auto"
                        : "col-lg-4 col-md-5 mx-auto"
                    } d-flex align-items-start mt-3`}
                  >
                    <div className="leftSideCont">
                      {currentStep === 0 && <img src={step0} alt="step0" />}
                      {currentStep === 1 && <img src={step1} alt="step1" />}
                      {currentStep === 2 && <img src={step2} alt="step2" />}
                      {currentStep === 3 && <img src={step3} alt="step3" />}
                      {currentStep === 4 && <img src={step4} alt="step4" />}
                      {currentStep === 5 && <img src={step5} alt="step5" />}
                      {currentStep === 6 && <img src={step6} alt="step6" />}
                      {currentStep === 7 && <img src={step7} alt="step7" />}
                      {currentStep === 8 && <img src={step8} alt="step8" />}
                      {currentStep === 9 && <img src={step9} alt="step9" />}
                      {currentStep === 10 && <img src={step10} alt="step10" />}
                      {currentStep === 11 && <img src={step11} alt="step11" />}
                      {currentStep === 12 && <img src={step12} alt="step12" />}
                    </div>
                  </div>
                )}

                <div
                  className={` ${
                    currentStep === 9
                      ? "col-md-8 col-11 mx-auto pt-4"
                      : "col-xl-6 col-md-7 col-11 mx-auto"
                  } padding000`}
                >
                  {currentStep !== 13 && (
                    <div className="progress-container">
                      <div className="progress-text">
                        <span>
                          {`${Math.round(progressPercentage)}%`} Completed
                        </span>
                      </div>
                      <div className="progress-bar">
                        <div
                          className="progress-fill"
                          style={{ width: `${progressPercentage}%` }}
                        ></div>
                      </div>
                    </div>
                  )}

                  {currentStep === 0 && (
                    <div className="overflowHeight">
                      <div className="containerRight maxwidthClass">
                        <div className="rightHeading">
                          <h3>
                            Your Restaurant Name Discovered on D Foodo<br></br>{" "}
                            Platform for Order and more.
                          </h3>
                          <p>
                            Enter Your Restaurant/Hotel/Cafe Name to get Started
                          </p>
                        </div>
                        <div
                          className="col-sm-10"
                          style={{ position: "relative" }}
                        >
                          <div className="restName">
                            {(isRestaurantNameFocused || restaurantName) && (
                              <label className="HeadBussiness">
                                Restaurant Name*
                              </label>
                            )}
                            <input
                              type="text"
                              minLength={3}
                              maxLength={75}
                              placeholder="Restaurant Name*"
                              className={`form-control ${
                                isRestaurantNameFocused ? "active-input" : ""
                              } ${restaurantNameError ? "input-error" : ""} ${
                                shake ? "shake" : ""
                              }`}
                              value={restaurantName}
                              onChange={handleInputChange}
                              onFocus={() => setIsRestaurantNameFocused(true)}
                              onBlur={() => setIsRestaurantNameFocused(false)}
                            />

                            {restaurantNameError && (
                              <span className="conditonMsgSHow">
                                Please enter the restaurant name
                              </span>
                            )}
                          </div>
                        </div>
                        <div
                          className="col-sm-10"
                          style={{ position: "relative" }}
                        >
                          <div>
                            {(isAdditionalInfoFocused || additionalInfo) && (
                              <label className="HeadBussiness">
                                Restaurant Tagline
                              </label>
                            )}
                            <input
                              type="text"
                              placeholder="Restaurant Tagline"
                              className={`form-control ${
                                isAdditionalInfoFocused ? "active-input" : ""
                              }`}
                              value={additionalInfo}
                              onChange={handleAdditionalInfoChange}
                              onFocus={() => setIsAdditionalInfoFocused(true)}
                              onBlur={() => setIsAdditionalInfoFocused(false)}
                            />
                          </div>
                        </div>
                        <div
                          className="col-sm-10 mt-2"
                          style={{ position: "relative" }}
                        >
                          <div>
                            {(isRestaurantDescriptionFocused ||
                              restoDistribution) && (
                              <label className="HeadBussiness">
                                Restaurant Description
                              </label>
                            )}
                            <textarea
                              placeholder="Let Customers learn more about your restaurant by adding ad description to your Restaurant Profile on Search."
                              className={`form-control resTdiscription ${
                                isRestaurantDescriptionFocused
                                  ? "active-input"
                                  : ""
                              }`}
                              defaultValue={restoDistribution}
                              onChange={handleRestoDiscribtionChange}
                              onFocus={() =>
                                setIsRestaurantDescriptionFocused(true)
                              }
                              onBlur={() =>
                                setIsRestaurantDescriptionFocused(false)
                              }
                            />
                          </div>
                        </div>
                        <div className="NextButton">
                          <button
                            onClick={handleNextStep}
                            style={{ marginTop: "0.5rem" }}
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    </div>
                  )}

                  {currentStep === 1 && (
                    <Onboadingstep1
                      nextStep={() => {
                        master_data_get();
                        dispatch(nextStep());
                      }}
                      prevStep={handlePrevStep}
                    />
                  )}
                  {currentStep === 2 && (
                    <Onboadingstep2
                      nextStep={() => {
                        master_data_get();
                        dispatch(nextStep());
                      }}
                      prevStep={handlePrevStep}
                    />
                  )}
                  {currentStep === 3 && (
                    <Onboadingstep3
                      nextStep={() => {
                        master_data_get();
                        dispatch(nextStep());
                      }}
                      prevStep={handlePrevStep}
                    />
                  )}
                  {currentStep === 4 && (
                    <Onboadingstep4
                      nextStep={() => {
                        master_data_get();
                        dispatch(nextStep());
                      }}
                      prevStep={handlePrevStep}
                    />
                  )}
                  {currentStep === 5 && (
                    <Onboadingstep5
                      nextStep={() => {
                        master_data_get();
                        dispatch(nextStep());
                      }}
                      prevStep={handlePrevStep}
                    />
                  )}
                  {currentStep === 6 && (
                    <Onboadingstep6
                      nextStep={() => {
                        master_data_get();
                        dispatch(nextStep());
                      }}
                      prevStep={handlePrevStep}
                    />
                  )}
                  {currentStep === 7 && (
                    <Onboadingstep7
                      nextStep={() => {
                        master_data_get();
                        dispatch(nextStep());
                      }}
                      prevStep={handlePrevStep}
                    />
                  )}
                  {currentStep === 8 && (
                    <Onboadingstep8
                      nextStep={() => {
                        master_data_get();
                        dispatch(nextStep());
                      }}
                      prevStep={handlePrevStep}
                    />
                  )}
                  {currentStep === 9 && (
                    <Onboadingstep9
                      nextStep={() => {
                        master_data_get();
                        dispatch(nextStep());
                      }}
                      prevStep={handlePrevStep}
                    />
                  )}
                  {currentStep === 10 && (
                    <Onboadingstep10
                      nextStep={() => {
                        master_data_get();
                        dispatch(nextStep());
                      }}
                      prevStep={handlePrevStep}
                    />
                  )}
                  {currentStep === 11 && (
                    <Onboadingstep11
                      nextStep={() => {
                        master_data_get();
                        dispatch(nextStep());
                      }}
                      prevStep={handlePrevStep}
                    />
                  )}
                  {currentStep === 12 && (
                    <Onboadingstep12
                      nextStep={() => {
                        master_data_get();
                        dispatch(nextStep());
                      }}
                      prevStep={handlePrevStep}
                      handlePrevStepcustom={handlePrevStepcustom}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
          {currentStep === 13 && (
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-6 col-sm-8 ">
                  <div className="EditForm">
                    <Onboadingstep13 />
                  </div>
                </div>
              </div>
            </div>
          )}
          {currentStep === 14 && (
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-6 col-sm-8 ">
                  <div className="EditForm">
                    <Onboadingstep14 />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default NewOnboading;
