import React, { useState, useEffect } from "react";
import "./Css/Header.css";
import "react-international-phone/style.css";
import phone from "../Assets/mobilePhone.svg";
import locationsssss from "../Assets/locationIcon.svg";
import mainLogo from "../Assets/mainLogo.png";
import qr from "../Assets/QR.png";
import { Modal, Button } from "react-bootstrap";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import locationbook from "../Assets/locationbook.svg";
import $ from "jquery";
import dropDown from "../Assets/downArrowBlack.svg";
import {
  handleAphabetsChange,
  handleEmailChange,
  handleError,
  handleLinkClick,
  handleNumbersChange,
  make_image_from_letter,
  validateEmail,
  validateMobile,
  getDistance,
} from "../CommonJquery/CommonJquery.js";
import {
  server_post_data,
  staff_login,
  city_list,
  APL_LINK,
} from "../ServiceConnection/serviceconnection.js";
import {
  removeData,
  retrieveData,
  storeData,
} from "../LocalConnection/LocalConnection.js";
let login_flag_res = "0";
let staff_id = "0";
let staff_name = "0";
let staff_mobile_no = "0";
let staff_email = "0";
let complete_status_one = "0";
let default_restaurant_id_staff = "0";
function Header() {
  const navigate = useNavigate();
  staff_id = retrieveData("staff_id");
  staff_name = retrieveData("staff_name");
  let check_custom_city = retrieveData("customer_city");
  let customer_city_add_by_location = retrieveData(
    "customer_city_add_by_location"
  );
  const customer_city =
    check_custom_city === "0" ? "Gothenburg" : check_custom_city;
  const profileShow = staff_id !== "0";
  const location = useLocation();
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [userNumber, setUserNumber] = useState("");
  const [otp, setOtp] = useState(""); // State to manage the entered OTP
  const [presentotp, setpresentotp] = useState("");
  const [isPhoneNumberValid, setisPhoneNumberValid] = useState(false);
  const [isOTPValid, setisisOTPValid] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(customer_city);
  const [cityImage, setCityImages] = useState([]);
  const [ImageLink, setImageLink] = useState("");
  const [showLocationModal, setShowLocationModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);
  const login_section_res = async () => {
    let vaild = "0";
    let login_otp = $("#opt_user").val();
    let user_email = $("#user_email").val();
    let user_name = $("#user_name").val();
    let user_last = $("#user_last").val();
    if (login_flag_res === "0") {
      if (!validateMobile(userNumber)) {
        vaild = "1";
      }
    }

    if (login_flag_res === "1") {
      if (parseInt(login_otp) === "") {
        vaild = "1";
      } else if (parseInt(login_otp) !== parseInt(presentotp)) {
        vaild = "1";
      } else {
        if (complete_status_one === "0") {
          $(".otp_section").hide();
          $(".last_section").show();
          login_flag_res = "2";
          return;
        } else {
          storeData("staff_id", staff_id);
          storeData("staff_name", staff_name);
          storeData("staff_mobile_no", staff_mobile_no);
          storeData("staff_email", staff_email);
          storeData("default_restaurant_id_staff", default_restaurant_id_staff);
          setTimeout(() => {
            window.location.reload();
          }, 800);
        }
      }
    }
    if (login_flag_res === "2") {
      if ($.trim(user_name) === "" || $.trim(user_last) === "") {
        vaild = "1";
      }
      if (user_email != "") {
        if (!validateEmail(user_email)) {
          vaild = "1";
          handleError("Enter Vaild Email Id");
          return;
        }
      }

      if (!$("#user_checkbox").prop("checked")) {
        vaild = "1";
        handleError(
          "Please agree to the terms and conditions before proceeding."
        );
        return;
      }
    }

    if (vaild === "0") {
      setshowLoaderAdmin(true);
      const fd = new FormData();
      fd.append("owner_moblie_no_without_zip", userNumber);
      if (parseInt(login_flag_res) > 0) {
        fd.append("click_type", "1");
      } else {
        fd.append("click_type", login_flag_res);
      }
      fd.append("email_id", user_email);
      fd.append("owner_name", user_name);
      fd.append("owner_lname", user_last);
      await server_post_data(staff_login, fd)
        .then((Response) => {
          setshowLoaderAdmin(false);
          if (Response.data.error) {
            handleError(Response.data.message);
          } else {
            if (Response.data.message.data_guest.length > 0) {
              setpresentotp(Response.data.message.staff_otp);
              if (
                Response.data.message.data_guest[0].admin_name === "" ||
                Response.data.message.data_guest[0].admin_name === null
              ) {
                complete_status_one = "0";
              } else {
                complete_status_one = "1";
              }
              staff_id = Response.data.message.data_guest[0].primary_id;
              staff_name = Response.data.message.data_guest[0].admin_name;
              staff_mobile_no =
                Response.data.message.data_guest[0].admin_mobile_no;
              staff_email = Response.data.message.data_guest[0].admin_email;
              default_restaurant_id_staff =
                Response.data.message.default_restaurant_id_staff;

              if (login_flag_res === "0") {
                $(".hide_ssection_profile").hide();
                $(".otp_section").show();
                login_flag_res = "1";
              } else {
                storeData("staff_id", staff_id);
                storeData("staff_name", staff_name);
                storeData("staff_mobile_no", staff_mobile_no);
                storeData("staff_email", staff_email);
                storeData(
                  "default_restaurant_id_staff",
                  default_restaurant_id_staff
                );

                setTimeout(() => {
                  handleLinkClick("");
                }, 800);
              }
            }
          }
        })
        .catch((error) => {
          setshowLoaderAdmin(false);
        });
    } else {
      if (login_flag_res === "0") {
        handleError("Enter Vaild Mobile No");
      } else if (login_flag_res === "1") {
        handleError("Enter Vaild OTP");
      } else {
        handleError("Enter Vaild Full name");
      }
    }
  };

  $("#login_check_jquery_staff").on("customEvent", function () {
    handleOpenLoginModal();
  });

  const handleCloseLoginModal = () => {
    setUserNumber("");
    setShowLoginModal(false);
  };
  const handleOpenLoginModal = () => {
    login_flag_res = "0";
    $(".hide_ssection_profile").show();
    $(".otp_section").hide();
    $(".last_section").hide();
    setShowLoginModal(true);
  };

  // location modal

  const handleCloseLocationModal = () => {
    setShowLocationModal(false);
  };
  const handleShowLocationModal = () => setShowLocationModal(true);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 992);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const detectLocation = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          master_data_get(
            1,
            position.coords.latitude,
            position.coords.longitude
          );
        },
        (err) => {
          console.log("Error getting location: " + err.message);
        }
      );
    } else {
      console.log("Geolocation is not supported by your browser");
    }
  };

  const master_data_get = async (flag, userLat, userLon) => {
    let minDistance = Infinity;
    let nearest = null;
    // console.log(city_list);
    await server_post_data(city_list, null)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          setCityImages(Response.data.message.cities);
          setImageLink(Response.data.message.image_link);
          if (
            userLat !== 0 &&
            customer_city_add_by_location === "0" &&
            flag === 1
          ) {
            Response.data.message.cities.forEach((city) => {
              const distance = getDistance(
                userLat,
                userLon,
                city.lat,
                city.lng
              );

              if (distance < minDistance) {
                minDistance = distance;
                nearest = { ...city, distance };
              }
            });
            storeData("customer_city", nearest.city);
            storeData("customer_city_add_by_location", nearest.city);
            storeData("customer_city_lat", userLat);
            storeData("customer_city_long", userLon);
            setSelectedLocation(nearest.city);
            handleCloseLocationModal();
            setTimeout(() => {
              var event = new CustomEvent("customEvent");
              document
                .getElementById("reload_page_data_staff")
                .dispatchEvent(event);
            }, 500);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    master_data_get(0, 0, 0);
    detectLocation();
  }, []);

  const handleLocationChange = (selectedOptioncity, selectedOptioncountry) => {
    storeData("customer_city", selectedOptioncity);
    storeData("customer_country", selectedOptioncountry);
    storeData("customer_city_add_by_location", selectedOptioncity);
    setSelectedLocation(selectedOptioncity);
    handleCloseLocationModal();
    setTimeout(() => {
      var event = new CustomEvent("customEvent");
      document.getElementById("reload_page_data_staff").dispatchEvent(event);
    }, 500);
  };

  return (
    <>
      <div className="upper_header_wrapper">
        <div className="container-lg">
          {location.pathname !== "/onBoarding" && !isMobile && (
            <div className="upper_header_container ">
              {" "}
              <Link onClick={() => handleLinkClick("/registerMyVenue")}>
                List My Restaurant
              </Link>
              <Link onClick={() => handleLinkClick("/getHelp")}>Get help</Link>
              {profileShow && <Link onClick={() => removeData()}>Logout</Link>}
              {/* <p>EN</p> */}
            </div>
          )}
        </div>
      </div>
      <nav className="navbar stickyHeader navbar-expand-lg bg-body-tertiary">
        <div className="container-lg">
          <Link className="navbar-brand" onClick={() => handleLinkClick("/")}>
            <img src={mainLogo} alt="mainLogo" width={150} />
          </Link>
          <div className="d-flex">
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarScroll"
              aria-controls="navbarScroll"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            {profileShow && (
              <div id="image_from_letter" className="d-lg-none">
                <img
                  src={make_image_from_letter(staff_name)}
                  onError={(e) => {
                    e.target.src = mainLogo; // Provide the path to your fallback image
                  }}
                  alt={staff_name}
                />
              </div>
            )}
          </div>
          <div className="collapse navbar-collapse" id="navbarScroll">
            <ul className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll">
              <li className="nav-item">
                {location.pathname !== "/onBoarding" && (
                  <span
                    className="dropdown1"
                    onClick={() => handleShowLocationModal()}
                  >
                    <label>
                      <img src={locationsssss} alt="location" />{" "}
                      {selectedLocation} <img src={dropDown} alt="dropDown" />
                    </label>
                  </span>
                )}
              </li>
              <li className="nav-item"></li>
            </ul>
            <form className="d-flex mobile_wrapper_header" role="search">
              <ul className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll">
                <li className="nav-item dropdown">
                  <div className="mobile_phone_container">
                    {location.pathname !== "/onBoarding" && (
                      <div
                        className="nav-link dropdown-toggle"
                        onClick={() => handleOpenModal()}
                      >
                        <img src={phone} alt=" phone" /> MOBILE
                      </div>
                    )}
                  </div>
                </li>
                <li>
                  {location.pathname !== "/onBoarding" && isMobile && (
                    <div className="upper_header_container_mobile">
                      <Link onClick={() => handleLinkClick("/registerMyVenue")}>
                        List My Restaurant
                      </Link>
                      <Link onClick={() => handleLinkClick("/getHelp")}>
                        Get help
                      </Link>
                      {profileShow && (
                        <Link
                          onClick={() => {
                            removeData();
                          }}
                        >
                          Logout
                        </Link>
                      )}
                      {/* <p>EN</p> */}
                    </div>
                  )}
                </li>
                <li>
                  {!profileShow && (
                    <Link
                      className="loginButtonMobile"
                      style={{
                        textDecoration: "none",
                        alignItems: "center",
                        width: "fitContent",
                      }}
                      onClick={() => handleOpenLoginModal()}
                    >
                      <p>Login</p>
                    </Link>
                  )}
                </li>
                <li className="nav-item"></li>
              </ul>
              {!profileShow && (
                <Link
                  className="loginButton"
                  style={{
                    textDecoration: "none",
                    alignItems: "center",
                    width: "fitContent",
                  }}
                  onClick={() => handleOpenLoginModal()}
                >
                  <p>Login</p>
                </Link>
              )}
              {profileShow && (
                <Link id="image_from_letter" className="d-none d-lg-flex">
                  <img
                    src={make_image_from_letter(staff_name)}
                    onError={(e) => {
                      e.target.src = mainLogo; // Provide the path to your fallback image
                    }}
                    alt={staff_name}
                  />
                </Link>
              )}
            </form>
          </div>
        </div>
      </nav>
      <Modal
        className="login_modal modal-md"
        centered
        show={showLoginModal}
        onHide={handleCloseLoginModal}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="phoneLoginModal_body">
          <div className="hide_ssection_profile">
            <h6>Enter your Phone Number</h6>
            <p>You will receive a text message to verify your account.</p>
            <PhoneInput
              id="phone"
              name="phone"
              placeholder="Phone Number"
              className="mt-2"
              defaultCountry="in"
              value={userNumber}
              onChange={(phone) => {
                setUserNumber(phone);
                setisPhoneNumberValid(phone.length >= 13);
              }}
              regions={["asia"]}
              onlyCountries={["in"]}
            />
            <Button
              className="PhoneloginButton mt-4 width100per"
              onClick={() => login_section_res()}
              type="button"
              style={{
                backgroundColor: !isPhoneNumberValid ? "grey" : "",
                borderColor: !isPhoneNumberValid ? "grey" : "",
                cursor: !isPhoneNumberValid ? "not-allowed" : "pointer",
              }}
              disabled={!isPhoneNumberValid}
            >
              Continue
            </Button>
          </div>
          <div className="otp_section">
            <h6>Enter the OTP</h6>
            <p>Please enter the OTP sent to your phone.</p>
            <input
              type="text"
              id="opt_user"
              name="opt_user"
              placeholder="Enter verification code"
              className="mt-2 form-control border0"
              onInput={handleNumbersChange}
              maxLength={4}
              value={otp}
              onChange={(e) => {
                setOtp(e.target.value);
                setisisOTPValid(
                  parseInt(e.target.value) === parseInt(presentotp)
                );
              }}
            />
            <Button
              className="PhoneloginButton mt-4 width100per"
              onClick={() => login_section_res()}
              type="button"
              style={{
                backgroundColor: !isOTPValid ? "grey" : "",
                borderColor: !isOTPValid ? "grey" : "",
                cursor: !isOTPValid ? "not-allowed" : "pointer",
              }}
              disabled={!isOTPValid}
            >
              Continue
            </Button>
          </div>
          <div className="last_section">
            <h4>Welcome to Dfoodo </h4>
            <p>Create your account and quickly make a reservation </p>
            <form className="userRegistration_form">
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="user_name"
                  name="user_name"
                  placeholder="First Name"
                  maxLength={50}
                  onInput={handleAphabetsChange}
                />
              </div>

              <div className="mb-3">
                <input
                  type="text"
                  id="user_last"
                  name="user_last"
                  className="form-control"
                  placeholder="Last Name"
                  maxLength={50}
                  onInput={handleAphabetsChange}
                />
              </div>

              <input
                type="text"
                id="user_email"
                name="user_email"
                className="form-control"
                placeholder="Email ID"
                maxLength={100}
                onInput={handleEmailChange}
              />
              <div className="mb-3 dfoodoterms_agreement ">
                <input
                  type="checkbox"
                  id="user_checkbox"
                  name="user_checkbox"
                  value="0"
                  className="wifth_chckbox"
                />
                <p>
                  I agree to Dfoodo{" "}
                  <span
                    style={{
                      display: "inline-block",
                      color: "var(--primary-color)",
                      backgroundColor: "transparent",
                      padding: "0",
                      cursor: "pointer",
                    }}
                    to="termsOfUse"
                    onClick={() => navigate("/termsOfUse")}
                  >
                    Terms & Conditions
                  </span>{" "}
                  and{" "}
                  <span
                    style={{
                      color: "var(--primary-color)",
                      backgroundColor: "transparent",
                      padding: "0",
                      cursor: "pointer",
                    }}
                    to="privacyPolicy"
                    onClick={() => navigate("/privacyPolicy")}
                  >
                    Privacy Policy
                  </span>
                </p>
              </div>
              <Button
                className="PhoneloginButton mt-4 width100per"
                onClick={() => login_section_res()}
                type="button"
                style={{
                  cursor: "pointer",
                }}
              >
                Complete Profile
              </Button>
            </form>
          </div>
        </Modal.Body>
      </Modal>
      <div id="login_check_jquery_staff"></div>
      <div id="reload_page_data_staff"></div>

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        centered
        className="success_modal_reg modal-md"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="phoneApp_modal_register_my_venue ">
            <h3>
              Find the best restaurants around you using{" "}
              <span style={{ color: "var(--primary-color)" }}>Dfoodo App!</span>
            </h3>
            <p>Download Dfoodo app by scanning the QR code</p>
            <img src={qr} alt="altqr" />
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showLocationModal}
        onHide={handleCloseLocationModal}
        className="modal-lg location"
      >
        <Modal.Header>
          <Modal.Title>
            <div className="row section_modal_heading">
              <div
                className="d-flex col-lg-12 location_wrapper_headerModal"
                onClick={() => detectLocation()}
              >
                <img src={locationbook} alt="locationbook" />
                <h6>Detect my Location</h6>
              </div>
              <hr></hr>
              <div className="popular_cities_header">
                {selectedLocation && (
                  <p className="location_modal_text">
                    Selected City:{" "}
                    <span style={{ color: "#E33745" }}>{selectedLocation}</span>
                  </p>
                )}
              </div>

              <div className="cities_mapped_locationModal  ">
                {cityImage &&
                  cityImage.map((value, idx) => (
                    <div
                      className={`HoverImg ${
                        selectedLocation === value.city ? "active" : ""
                      }`}
                      key={idx}
                    >
                      <Link
                        to={"/"}
                        className="city_container"
                        onClick={() => {
                          handleLocationChange(value.city, value.country);
                        }}
                      >
                        <img
                          src={`${APL_LINK + ImageLink + value.image_name}`}
                          alt={value.city}
                        />
                        <p
                          className={`citySelecttt ${
                            selectedLocation === value.city ? "active" : ""
                          }`}
                        >
                          {value.city}
                        </p>
                      </Link>
                    </div>
                  ))}
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
      </Modal>
    </>
  );
}

export default Header;
