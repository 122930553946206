import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import Header from "./Header";
import filter from "../Assets/filter.svg";
import datepicker from "../Assets/datepicker.svg";
import "../Components/Css/Venue.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import rightArrow from "../Assets/rightArrow.svg";
import rigthArrow from "../Assets/right_svg_button.svg";
import leftArrow from "../Assets/left_svg_button.svg";
import star from "../Assets/star.svg";
import Footer from "./Footer";
import Heart from "../Assets/heart.svg";
import HeartRed from "../Assets/HeartRed.svg";
import DownloadApp from "./DownloadApp";
import AreYouAVenueOwner from "./AreYouAVenueOwner";
import contactus from "../Assets/averagePrice.svg";
import {
  server_post_data,
  save_favourite,
  get_categorypage_webapp,
  APL_LINK,
} from "../ServiceConnection/serviceconnection";
import {
  formatTimeintotwodigit,
  handleError,
  handleLinkClick,
} from "../CommonJquery/CommonJquery.js";
import { retrieveData } from "../LocalConnection/LocalConnection.js";
let customer_id = "0";
let PageNumber = 1;
let DataLoadNo = 1;

const Venue = () => {
  customer_id = retrieveData("customer_id");
  const location = useLocation();
  const currentUrl = location.pathname.substring(1);
  const [GetVenueData, SetVenueData] = useState([]);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState([]);
  const [numberOfVenuesFound, setNumberOfVenuesFound] = useState(0);
  const [ImageLink, setImageLink] = useState("");
  const [HeartImg, setHeartImages] = useState([]);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [SEOloop, setSEOloop] = useState([]);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [selectedSort, setSelectedSort] = useState("Popularity");
  const [selectedCuisines, setSelectedCuisines] = useState([]);
  const [selectedDiscounts, setSelectedDiscounts] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedPrice, setSelectedPrice] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [FinishDataLoad, setFinishDataLoad] = useState(false);

  const [CuisinesData, setCuisinesData] = useState([]);
  const [DiscountsData, setDiscountsData] = useState([]);
  const [CategoryData, setCategoryData] = useState([]);
  const [PriceData, setPriceData] = useState([]);

  useEffect(() => {
    // Log when the effect runs
  }, [
    selectedDiscounts,
    selectedCuisines,
    selectedCategory,
    selectedPrice,
    selectedSort,
  ]);
  const handleCuisineChange = (value, e) => {
    setSelectedCuisines((prev) => {
      if (e.target.checked) {
        return prev.includes(value) ? prev : [...prev, value];
      } else {
        return prev.filter((item) => item.cuisine_name !== value.cuisine_name);
      }
    });
  };
  const [selectedDate, setSelectedDate] = useState(
    new Date().toISOString().slice(0, 10)
  );

  const handleDateChange = (date) => {
    const date_data = new Date(date);
    const formattedDate = date_data.toISOString().slice(0, 10);
    setSelectedDate(formattedDate);
    applyfilter(formattedDate);
  };

  const handleDiscountChange = (value, e) => {
    setSelectedDiscounts((prev) => {
      if (e.target.checked) {
        return prev.includes(value) ? prev : [...prev, value];
      } else {
        return prev.filter((item) => item.value !== value.value);
      }
    });
  };

  const handleCategoryChange = (value, e) => {
    setSelectedCategory((prev) => {
      if (e.target.checked) {
        return prev.includes(value) ? prev : [...prev, value];
      } else {
        return prev.filter(
          (item) => item.category_master_name !== value.category_master_name
        );
      }
    });
  };
  const handlePriceChange = (value, e) => {
    setSelectedPrice(value);
  };
  const master_data_get = async (selectedSort_data, special_date) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    selectedCuisines.forEach((cuisine) =>
      fd.append("cuisine[]", cuisine.cuisine_name)
    );
    selectedDiscounts.forEach((discount) =>
      fd.append("discount[]", discount.value)
    );
    selectedCategory.forEach((category) =>
      fd.append("category[]", category.category_master_name)
    );
    {
      selectedPrice &&
        selectedPrice.value &&
        fd.append("resturantprice", selectedPrice.value);
    }
    fd.append("current_url", "/" + currentUrl);

    fd.append("selected_sort", selectedSort_data);
    fd.append("page_number", PageNumber);
    fd.append("special_date", special_date);
    await server_post_data(get_categorypage_webapp, fd)
      .then((Response) => {
        handleCloseFilterModal();
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          let venues = Response.data.message.restro;
          let restro_count = Response.data.message.restro_count;
          DataLoadNo = PageNumber;
          if (PageNumber > 1) {
            const newVenues = [...GetVenueData, ...venues];
            SetVenueData(newVenues);
          } else {
            SetVenueData(venues);
            setNumberOfVenuesFound(restro_count);
            setImageLink(Response.data.message.image_link);
            setSEOloop(Response.data.message.data_seo);
            setHeartImages(Response.data.message.favourite);
            setCuisinesData(Response.data.message.cuisine_data);
            setDiscountsData(Response.data.message.deal_options);
            setCategoryData(Response.data.message.category_data);
            setPriceData(Response.data.message.price_for_two_options);
            const venueData = venues[0];
            const catagoryData = venues[0].category[0];

            // Extract categories
            const newBreadcrumbs = [
              { name: "Home", path: "/" },
              {
                name: venueData.restaurant_country,
                path: "/" || "",
              },
              { name: venueData.restaurant_city || "" },
              {
                name: catagoryData.category_master_name || "",
                path: "",
              },
            ];
            setBreadcrumbs(newBreadcrumbs);
          }
          if (venues && venues.length < 1) {
            setFinishDataLoad(true);
          }
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        handleCloseFilterModal();
        setshowLoaderAdmin(false);
      });
  };
  useEffect(() => {
    master_data_get(selectedSort, selectedDate);
  }, []);

  const handleCloseFilterModal = () => setShowFilterModal(false);
  const handleShowFilterModal = () => setShowFilterModal(true);

  const venues_data_labeled = GetVenueData;
  // pagination of popular venues
  const [currentPaginationPage, setCurrentPaginationPage] = useState(1);
  const itemsPerPage = 6;

  const totalPaginationPages = Math.ceil(
    venues_data_labeled.length / itemsPerPage
  );

  const handleNextPage = () => {
    if (!FinishDataLoad) {
      setCurrentPaginationPage((prevPage) =>
        Math.min(prevPage + 1, totalPaginationPages)
      );
      PageNumber = PageNumber + 1;
      if (currentPaginationPage + 1 > DataLoadNo) {
        master_data_get(selectedSort, selectedDate);
      }
    }
  };

  const handlePreviousPage = () => {
    setCurrentPaginationPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const indexOfLastItem = currentPaginationPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentPaginationItems = venues_data_labeled.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  // filter modal states

  const handleSortChange = (e) => {
    setSelectedSort(e.target.value);
  };

  // Toggle the like state for a specific venue
  const handleHeartClick = (venueId) => {
    if (customer_id !== "0") {
      handleSaveChangesdynamic(venueId);
    } else {
      var event = new CustomEvent("customEvent");
      document.getElementById("login_check_jquery").dispatchEvent(event);
    }
  };

  const isFavorite = (venueId) => {
    return HeartImg.some((fav) => fav.restaurant_id === venueId);
  };

  const handleSaveChangesdynamic = async (venueId) => {
    const form_data = new FormData();
    form_data.append("venue_id", venueId);
    await server_post_data(save_favourite, form_data)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          try {
            const updatedFavorites = HeartImg.some(
              (fav) => fav.restaurant_id === venueId
            )
              ? HeartImg.filter((fav) => fav.restaurant_id !== venueId) // Remove from favorites
              : [...HeartImg, { restaurant_id: venueId }];

            // Update state with the new list of favorites
            setHeartImages(updatedFavorites);
          } catch (error) {
            console.error("Error updating favorite status:", error);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const match_and_return_seo_link = (v_id) => {
    let data_seo_link_final = "/restro/restro_detail/" + v_id;
    let data_seo_link = data_seo_link_final;
    if (SEOloop) {
      const matchedItem = SEOloop.find((data) => {
        return data_seo_link === data.call_function_name;
      });

      if (matchedItem) {
        data_seo_link_final = matchedItem.pretty_function_name;
      }
    }
    return data_seo_link_final;
  };

  const applyfilter = (date = null) => {
    PageNumber = 1;
    DataLoadNo = 1;
    setCurrentPaginationPage(1);
    if (date == null) {
      master_data_get(selectedSort, selectedDate);
    } else {
      master_data_get(selectedSort, date);
    }
  };
  const clearAllFilters = () => {
    setSelectedSort("Popularity");
    setSelectedDiscounts([]);
    setSelectedCuisines([]);
    setSelectedCategory([]);
    setSelectedPrice(null);
    setSelectedDate(null);
    setSelectedTab(0);

    handleCloseFilterModal();
  };

  const getPlaceholderText = () => {
    if (!selectedDate) {
      return "Today";
    }

    const today = new Date();
    if (format(selectedDate, "dd-MM-yyyy") === format(today, "dd-MM-yyyy")) {
      return "Today";
    } else {
      return format(selectedDate, "dd-MM-yyyy");
    }
  };

  return (
    <>
      <div>
        <Header />
        {/* venue categories section */}
        <section>
          <div className="container-lg mt-3 mb-1">
            <div className="venuePage_venueCategory_heading">
              {breadcrumbs.map((crumb, index) => (
                <React.Fragment key={index}>
                  {index > 0 && <img src={rightArrow} alt="rightArrow" />}
                  {index === breadcrumbs.length - 1 ? (
                    <span>{crumb.name}</span>
                  ) : (
                    <Link to={crumb.path}>{crumb.name}</Link>
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>
        </section>
        <section>
          <div className="filters_section">
            <div className="container-lg">
              <div className="filters_wrapper">
                <ul>
                  <li
                    onClick={() => handleShowFilterModal()}
                    id="filter_filter_row"
                  >
                    <img src={filter} alt="filter" /> Filter
                  </li>
                  <li className="datepicker">
                    <label htmlFor="dateFilter">
                      <img src={datepicker} alt="datepicker" />
                    </label>
                    <DatePicker
                      selected={selectedDate}
                      onChange={handleDateChange}
                      placeholderText={getPlaceholderText()}
                      dateFormat="dd-MM-yyyy"
                      minDate={new Date()}
                      className="custom-date-picker"
                      id="dateFilter"
                    />
                  </li>
                  {selectedDiscounts.map((text, index) => (
                    <li key={index} className={`filters_section_row selected`}>
                      {text.label}
                    </li>
                  ))}
                  {selectedCuisines.map((text, index) => (
                    <li key={index} className={`filters_section_row selected`}>
                      {text.cuisine_name}
                    </li>
                  ))}
                  {selectedCategory.map((text, index) => (
                    <li key={index} className={`filters_section_row selected`}>
                      {text.category_master_name}
                    </li>
                  ))}
                  {selectedPrice && selectedPrice.label && (
                    <li className={`filters_section_row selected`}>
                      {selectedPrice.label}
                    </li>
                  )}

                  <li className={`filters_section_row selected`}>
                    {selectedSort}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section className="found_venues_section">
          <div className="container-lg">
            <section>
              <div className="popularVenues_section">
                <div className="">
                  <div className="popularVenues_heading_container">
                    <h5>{numberOfVenuesFound} Restaurant Found</h5>
                    <span className="seAll_span">
                      <div className="pagination_controls">
                        <button
                          onClick={handlePreviousPage}
                          disabled={currentPaginationPage === 1}
                        >
                          <img src={leftArrow} alt="leftArrow" />
                        </button>
                        <button onClick={handleNextPage}>
                          <img src={rigthArrow} alt="rightArrow" />
                        </button>
                      </div>
                    </span>
                  </div>
                  <div className="popularVenues">
                    <div className="row mt-1">
                      {currentPaginationItems.map((venue, index) => (
                        <div
                          key={index}
                          className="col-lg-6 col-sm-12 margin24px"
                          style={{ overflow: "hidden" }}
                        >
                          <div className="VenuePage_venue_container">
                            <div className="LikedHeart">
                              <button
                                onClick={() =>
                                  handleHeartClick(venue.primary_id)
                                }
                              >
                                <img
                                  src={
                                    isFavorite(venue.primary_id)
                                      ? HeartRed
                                      : Heart
                                  }
                                  alt="Heart"
                                />
                              </button>
                            </div>
                            <Link
                              onClick={() => {
                                handleLinkClick(
                                  match_and_return_seo_link(venue.primary_id)
                                );
                              }}
                              style={{ textDecoration: "none" }}
                            >
                              <div className="row m-0">
                                <div className="col-sm-5 p-0">
                                  <div className="venuePage_image_container">
                                    <img
                                      src={
                                        APL_LINK +
                                        ImageLink +
                                        venue.restaurant_image
                                      }
                                      alt={venue.restaurant_name}
                                    />
                                    <div className="venuePage_ratingSection">
                                      <p>{venue.rating || "N/A"}</p>
                                      <img src={star} alt="star" />
                                    </div>
                                  </div>
                                </div>

                                <div className="col-sm-7 ">
                                  <div className="venuePage_text_section">
                                    <div className="venueContainer_rowtext">
                                      <div className="venueContainer_nameAndAddress">
                                        <h6>
                                          {venue.restaurant_name || "No Name"}
                                        </h6>
                                      </div>
                                    </div>
                                    <p className="ellipsis">
                                      {venue.restaurant_full_adrress ||
                                        "No  add"}
                                    </p>

                                    <h6 className="avrgPrice">
                                      <img
                                        src={contactus}
                                        alt="contactus"
                                        width={15}
                                      />
                                      Average Price {venue.first_money_icon}{" "}
                                      {venue.restaurant_price}{" "}
                                      {venue.last_money_icon}
                                    </h6>
                                    <span className="venuePage_venue_category_titles marginNone">
                                      {venue.amenities?.map(
                                        (amenity, amenityIndex) => (
                                          <div
                                            key={amenityIndex}
                                            className="amenityItem"
                                          >
                                            <img
                                              src={`${APL_LINK}/assets/${amenity.image}`}
                                              alt={amenity.amenities_name}
                                            />
                                            <label>
                                              {amenity.amenities_name}
                                            </label>
                                          </div>
                                        )
                                      )}
                                    </span>

                                    <div className="TimingButtons2">
                                      {venue.timing?.map(
                                        (timeshow, amenityIndex) => (
                                          <div
                                            className="timesBtns"
                                            key={amenityIndex}
                                          >
                                            <p>
                                              {" "}
                                              {formatTimeintotwodigit(
                                                timeshow.start_time
                                              )}
                                            </p>
                                            <div className="childtime">
                                              {timeshow.per_discount > 0 ? (
                                                <p>-{timeshow.per_discount}%</p>
                                              ) : (
                                                <p>N/A</p>
                                              )}
                                            </div>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
        <section className="venuePage_listyourVenue_section">
          <DownloadApp />
        </section>
        <section>
          <AreYouAVenueOwner />
        </section>
        <Footer />
      </div>

      <Modal
        show={showFilterModal}
        onHide={handleCloseFilterModal}
        centered
        className="filterMOdal modal-lg"
      >
        <Modal.Header closeButton className="flex-row">
          <Modal.Title className="filters_modal_heading ">Filters</Modal.Title>
        </Modal.Header>
        <Modal.Body id="filters_modal">
          <div className="tbContainer">
            <div className="row m-0">
              <div className="col-lg-4 col-5" style={{ paddingLeft: "0" }}>
                <div className="tabList FilterTablist">
                  <div
                    className={selectedTab === 0 ? "tab active" : "tab"}
                    onClick={() => setSelectedTab(0)}
                  >
                    <p>Sort by</p>
                  </div>
                  <div
                    className={selectedTab === 1 ? "tab active" : "tab"}
                    onClick={() => setSelectedTab(1)}
                  >
                    <p>Deals</p>
                  </div>
                  <div
                    className={selectedTab === 2 ? "tab active" : "tab"}
                    onClick={() => setSelectedTab(2)}
                  >
                    <p>Cuisines</p>
                  </div>
                  <div
                    className={selectedTab === 3 ? "tab active" : "tab"}
                    onClick={() => setSelectedTab(3)}
                  >
                    <p>Category </p>
                  </div>
                  <div
                    className={selectedTab === 4 ? "tab active" : "tab"}
                    onClick={() => setSelectedTab(4)}
                  >
                    <p>Cost for Two</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-7">
                <div className="tabpanel">
                  {selectedTab === 0 && (
                    <div>
                      <form className="filters_modal_venuesPage">
                        <label>
                          <input
                            type="radio"
                            name="sort"
                            value="Popularity"
                            checked={selectedSort === "Popularity"}
                            onChange={handleSortChange}
                          />
                          Popularity
                        </label>
                        <br />
                        <label>
                          <input
                            type="radio"
                            name="sort"
                            value="Rating: High to Low"
                            checked={selectedSort === "Rating: High to Low"}
                            onChange={handleSortChange}
                          />
                          Rating: High to Low
                        </label>
                        <br />
                        <label>
                          <input
                            type="radio"
                            name="sort"
                            value="Cost: Low to High"
                            checked={selectedSort === "Cost: Low to High"}
                            onChange={handleSortChange}
                          />
                          Cost: Low to High
                        </label>
                        <br />
                        <label>
                          <input
                            type="radio"
                            name="sort"
                            value="Cost: High to Low"
                            checked={selectedSort === "Cost: High to Low"}
                            onChange={handleSortChange}
                          />
                          Cost: High to Low
                        </label>
                      </form>
                    </div>
                  )}
                  {selectedTab === 1 && (
                    <div>
                      <div>
                        <form className="filters_modal_venuesPage">
                          <div className="row">
                            <div className="col-7 d-flex flex-column">
                              {DiscountsData.map((crumb, index) => (
                                <label className="mb-2" key={index}>
                                  <input
                                    type="checkbox"
                                    name="sort"
                                    value={crumb}
                                    defaultChecked={selectedDiscounts.some(
                                      (fav) => fav.value === crumb.value
                                    )}
                                    onChange={(e) =>
                                      handleDiscountChange(crumb, e)
                                    }
                                  />
                                  {crumb.label}
                                </label>
                              ))}
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  )}
                  {selectedTab === 2 && (
                    <div>
                      <div>
                        <form className="filters_modal_venuesPage">
                          <div className="row">
                            <div className="col-7 d-flex flex-column">
                              {CuisinesData.map((crumb, index) => (
                                <label className="mb-2" key={index}>
                                  <input
                                    type="checkbox"
                                    name="sort"
                                    value={crumb}
                                    defaultChecked={selectedCuisines.some(
                                      (fav) =>
                                        fav.cuisine_name === crumb.cuisine_name
                                    )}
                                    onChange={(e) =>
                                      handleCuisineChange(crumb, e)
                                    }
                                  />
                                  {crumb.cuisine_name}
                                </label>
                              ))}
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  )}
                  {selectedTab === 3 && (
                    <div>
                      <div>
                        <form className="filters_modal_venuesPage">
                          <div className="row">
                            <div className="col-7 d-flex flex-column">
                              {CategoryData.map((crumb, index) => (
                                <label className="mb-2" key={index}>
                                  <input
                                    type="checkbox"
                                    name="sort"
                                    value={crumb}
                                    defaultChecked={selectedCategory.some(
                                      (fav) =>
                                        fav.category_master_name ===
                                        crumb.category_master_name
                                    )}
                                    onChange={(e) =>
                                      handleCategoryChange(crumb, e)
                                    }
                                  />
                                  {crumb.category_master_name}
                                </label>
                              ))}
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  )}
                  {selectedTab === 4 && (
                    <div>
                      <div>
                        <form className="filters_modal_venuesPage">
                          <div className="row">
                            <div className="col-7 d-flex flex-column">
                              {PriceData.map((crumb, index) => (
                                <label className="mb-2" key={index}>
                                  <input
                                    type="radio"
                                    name="sort"
                                    value={crumb}
                                    defaultChecked={
                                      selectedPrice &&
                                      selectedPrice.value &&
                                      selectedPrice.value === crumb.value
                                    }
                                    onChange={(e) =>
                                      handlePriceChange(crumb, e)
                                    }
                                  />
                                  {crumb.label}
                                </label>
                              ))}
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="filter_modal_button">
          <Button onClick={clearAllFilters}>Clear All</Button>
          <Button onClick={() => applyfilter()}>Apply</Button>
        </Modal.Footer>
      </Modal>

      {/* Date Picker */}

      {/* <Modal
      show={showDatePickerModal}
      onHide={handleCloseDatePickerModal}
      centered
      className="modal-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title className="DatePicker_modal_heading">Select A Date</Modal.Title>
      </Modal.Header>
      <Modal.Body id="DatePicker_modal">
      <div className="tbContainer">
          <DatePicker
            selected={selectedDate}
            onChange={handleDateChange}
            dateFormat="dd/MM/yyyy"
            placeholderText="Select a date"
            className="custom-date-picker"
            customInput={
              <input
                type="text"
                value={selectedDate ? displayDate(selectedDate) : ""}
                readOnly
                className="custom-date-picker-input"
              />
            }
          />
        </div>
      </Modal.Body>
    </Modal> */}
    </>
  );
};

export default Venue;
