import React, { useState, useEffect } from "react";
import Swal from "sweetalert2"; // Import SweetAlert2
import backButton from "../Assets/backButton.svg";
import bluemark from "../Assets/bluemark.svg";
import redmark from "../Assets/redmark.svg";
import greenmark from "../Assets/greenmark.svg";
import "./Css/Newonboading.css";
import { retrieveData } from "../LocalConnection/LocalConnection";
import { handleError } from "../CommonJquery/CommonJquery";
import {
  sava_onboarding_form,
  get_last_resturant_data_save,
  server_post_data,
} from "../ServiceConnection/serviceconnection";
let staff_id = "0";
let default_restaurant_id_staff = "0";
const Onboadingstep4 = ({ nextStep, prevStep }) => {
  staff_id = retrieveData("staff_id");
  default_restaurant_id_staff = retrieveData("default_restaurant_id_staff");
  const [showLoader, setshowLoader] = useState(false);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [restaurantData, setrestaurantData] = useState([]);
  const restaurantOptions = [
    {
      img: bluemark,
      title: "All",
      description: "Customer can select both veg & nonveg",
    },
    {
      img: greenmark,
      title: "Veg",
      description: "Restaurant will be list for veg restaurant",
    },
    {
      img: redmark,
      title: "Non Veg",
      description: "Restaurant will be list for non veg restaurant",
    },
  ];

  const [selectedOption, setSelectedOption] = useState(0);

  const handleNextStep = () => {
    console.log(selectedOption);
    if (selectedOption === null) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please select at least one option!",
        confirmButtonText: "OK",
        confirmButtonColor: "#3085d6",
      });
    } else {
      handleSaveChangesdynamic();
    }
  };
  const handleSaveChangesdynamic = async () => {
    setshowLoader(true);
    const form_data = new FormData();
    form_data.append("owner_staff_id", staff_id);
    form_data.append("default_restaurant_id", default_restaurant_id_staff);
    form_data.append("veg_non_both_type", selectedOption);
    form_data.append("step_save", "4");

    await server_post_data(sava_onboarding_form, form_data)
      .then(async (Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          nextStep();
        }
        setshowLoader(false);
      })
      .catch((error) => {
        console.log(error);
        setshowLoader(false);
      });
  };

  useEffect(() => {
    master_data_get();
  }, []);

  const master_data_get = async () => {
    setshowLoaderAdmin(true);
    const form_data = new FormData();
    form_data.append("owner_staff_id", staff_id);
    form_data.append("default_restaurant_id", default_restaurant_id_staff);
    await server_post_data(get_last_resturant_data_save, form_data)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          if (Response.data.message.restaurant_data.length > 0) {
            setrestaurantData(Response.data.message.restaurant_data[0]);
            setSelectedOption(
              Response.data.message.restaurant_data[0].veg_non_both_type
            );
          }
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div className="mt-3">
      <div className="containerRight">
        <div className="rightHeading">
          <div className="d-flex BackHeading">
            <button onClick={prevStep}>
              <img src={backButton} alt="Back Button" />
            </button>
            <h3>Choose your Restaurant Food Type </h3>
          </div>
          <p>Select all that apply to your Restaurant</p>
        </div>

        <div className="overflowHeight">
        {restaurantOptions.map((option, index) => (
          <div key={index} className="col-sm-9 mb-3">
            <div className="box1">
              <div className="row align-items-center">
                <div className="col-2 d-flex justify-content-center">
                  <img src={option.img} alt={option.title} className="vegNon" />
                </div>
                <div className="col">
                  <div className="AllContent">
                    <p>{option.title}</p>
                    <label>{option.description}</label>
                  </div>
                </div>
                <div className="col-2">
                  <div className="inputCHeck2">
                    <label className="custom-radio">
                      <input
                        type="radio"
                        name="restaurantType"
                        checked={selectedOption === index}
                        onChange={() => setSelectedOption(index)}
                      />
                      <span className="custom-radio"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}

        <div className="NextButton">
          <button onClick={handleNextStep}>Next</button>
        </div>
        </div>
      </div>
    </div>
  );
};

export default Onboadingstep4;
