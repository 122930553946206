import React, { useState, useEffect } from "react";
import backButton from "../Assets/backButton.svg";
import rf1 from "../Assets/rf1.svg";
import rf2 from "../Assets/rf2.svg";
import rf3 from "../Assets/rf3.svg";
import rf4 from "../Assets/rf4.svg";
import rf5 from "../Assets/rf5.svg";
import rf6 from "../Assets/rf6.svg";
import rf7 from "../Assets/rf7.svg";
import rf8 from "../Assets/rf8.svg";
import "./Css/Newonboading.css";
import { retrieveData } from "../LocalConnection/LocalConnection";
import { handleError } from "../CommonJquery/CommonJquery";
import {
  sava_onboarding_form,
  get_last_resturant_data_save,
  server_post_data,
  APL_LINK,
} from "../ServiceConnection/serviceconnection";
let staff_id = "0";
let default_restaurant_id_staff = "0";
const Onboadingstep7 = ({ nextStep, prevStep }) => {
  staff_id = retrieveData("staff_id");
  default_restaurant_id_staff = retrieveData("default_restaurant_id_staff");
  const [showLoader, setshowLoader] = useState(false);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [restaurantData, setrestaurantData] = useState([]);
  const [restaurantDataImage, setrestaurantDataImage] = useState("");
  const [cuisines, setCuisines] = useState([]);
  const [amenities, setAmenities] = useState([]);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [selectedCheckboxes2, setSelectedCheckboxes2] = useState([]);

  const handleNext = () => {
    handleSaveChangesdynamic();
  };

  const handleSaveChangesdynamic = async () => {
    setshowLoader(true);
    const form_data = new FormData();
    let temp_array2 = [];
    let temp_array3 = [];
    const selectedAmenities = amenities.filter(
      (_, index) => selectedCheckboxes[index]
    );
    selectedAmenities.forEach((element) => {
      temp_array2.push(
        `${element.primary_id}~@~${element.amenities_name}~@~${element.image}`
      );
    });
    const selectedCuisine = cuisines.filter(
      (_, index) => selectedCheckboxes2[index]
    );
    selectedCuisine.forEach((element) => {
      temp_array3.push(`${element.primary_id}~@~${element.cuisine_name}`);
    });
    form_data.append("owner_staff_id", staff_id);
    form_data.append("default_restaurant_id", default_restaurant_id_staff);
    form_data.append("amenities", temp_array2);
    form_data.append("cuisine", temp_array3);
    form_data.append("step_save", "7");
    await server_post_data(sava_onboarding_form, form_data)
      .then(async (Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          nextStep();
        }
        setshowLoader(false);
      })
      .catch((error) => {
        console.log(error);
        setshowLoader(false);
      });
  };

  useEffect(() => {
    master_data_get();
  }, []);

  const master_data_get = async () => {
    setshowLoaderAdmin(true);
    const form_data = new FormData();
    form_data.append("owner_staff_id", staff_id);
    form_data.append("default_restaurant_id", default_restaurant_id_staff);
    await server_post_data(get_last_resturant_data_save, form_data)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          if (Response.data.message.restaurant_data.length > 0) {
            setrestaurantData(Response.data.message.restaurant_data[0]);
            setrestaurantDataImage(Response.data.message.image);
            setCuisines(Response.data.message.cuisine);
            setAmenities(Response.data.message.amenities);

            const initialCheckboxes = Response.data.message.amenities.map(
              (amenity) =>
                Response.data.message.amenitiesselected.some(
                  (selected) =>
                    parseInt(selected.amenities_id) ===
                    parseInt(amenity.primary_id)
                )
            );
            setSelectedCheckboxes(initialCheckboxes);
            const initialCheckboxes2 = Response.data.message.cuisine.map(
              (cuisine) =>
                Response.data.message.cuisineselected.some((selected) => {
                  return (
                    parseInt(selected.cuisine_id) ===
                    parseInt(cuisine.primary_id)
                  );
                })
            );
            setSelectedCheckboxes2(initialCheckboxes2);
          }
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleCheckboxChange = (index) => {
    const updatedCheckboxes = selectedCheckboxes.map((checked, i) =>
      i === index ? !checked : checked
    );
    setSelectedCheckboxes(updatedCheckboxes);
  };
  const handleCheckboxChange2 = (index) => {
    const updatedCheckboxes2 = selectedCheckboxes2.map((checked, i) =>
      i === index ? !checked : checked
    );
    setSelectedCheckboxes2(updatedCheckboxes2);
  };
  return (
    <div className="mt-3 RestCont">
      <div className="containerRight">
        <div className="rightHeading">
          <div className="d-flex BackHeading">
            <button onClick={prevStep}>
              <img src={backButton} alt="Back Button" />
            </button>
            <h3>Restaurant Features</h3>
          </div>
          <p>Select all that apply to your Restaurant</p>
        </div>
        <div className="overflowHeight">
        <div className="row marginLEft">
          <div className="col p-0">
            {amenities.map((option, index) => {
              if (!(index % 2)) {
                return (
                  <div className="leftSideContant retaruFeature" key={index}>
                    <div className="d-flex align-items-center">
                      <input
                        type="checkbox"
                        className="restraCheckbox"
                        checked={selectedCheckboxes[index]} // Set checkbox state
                        onChange={() => handleCheckboxChange(index)}
                      />
                      <img
                        src={APL_LINK + restaurantDataImage + option.image}
                        alt="Dfoodo Dashboard"
                      />
                      <label className="custom-label">
                        {option.amenities_name}
                      </label>
                    </div>
                  </div>
                );
              }
            })}
          </div>

          <div className="col p-0">
            {amenities.map((option, index) => {
              if (index % 2) {
                return (
                  <div className="leftSideContant retaruFeature" key={index}>
                    <div className="d-flex align-items-center">
                      <input
                        type="checkbox"
                        className="restraCheckbox"
                        checked={selectedCheckboxes[index]} // Set checkbox state
                        onChange={() => handleCheckboxChange(index)}
                      />
                      <img
                        src={APL_LINK + restaurantDataImage + option.image}
                        alt="Dfoodo Dashboard"
                      />
                      <label className="custom-label">
                        {option.amenities_name}
                      </label>
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </div>
        <div className="col-9">
            {/* <div
              className="d-flex w-100 justify-content-end mt-2"
              style={{ color: "#558ef2", fontSize: "14px" }}
            >
              +load more
            </div> */}
          </div>
        <h3 className="mt-3">Restaurant Cuisines</h3>
        <div className="row m-0 CuisenDIv">
          <div className="col-6 p-0">
            {cuisines.map((option, index) => {
              if (!(index % 2)) {
                return (
                  <div
                    className="d-flex align-items-center MarginBttm"
                    key={index}
                  >
                    <input
                      type="checkbox"
                      className="restraCheckbox"
                      checked={selectedCheckboxes2[index]} // Set checkbox state
                      onChange={() => handleCheckboxChange2(index)}
                    />

                    <label className="custom-label">
                      {option.cuisine_name}
                    </label>
                  </div>
                );
              }
            })}
          </div>

          <div className="col-6 p-0   ">
            {cuisines.map((option, index) => {
              if (index % 2) {
                return (
                  <div
                    className="d-flex align-items-center MarginBttm"
                    key={index}
                  >
                    <input
                      type="checkbox"
                      className="restraCheckbox"
                      checked={selectedCheckboxes2[index]} // Set checkbox state
                      onChange={() => handleCheckboxChange2(index)}
                    />

                    <label className="custom-label">
                      {option.cuisine_name}
                    </label>
                  </div>
                );
              }
            })}
          </div>
        </div>
        <div className="col-9">
            {/* <div
              className="d-flex w-100 justify-content-end"
              style={{ color: "#558ef2", fontSize: "14px" }}
            >
              +load more
            </div> */}
          </div>
        <div className="footerbtttn">
          {/* <div className="skipbutton" onClick={nextStep}>
            <button>Skip</button>
          </div> */}
          <div className="NextButton">
            <button onClick={handleNext} style={{ margin: 0 }}>
              Next
            </button>
          </div>
        </div>
        </div>
      </div>
    </div>
  );
};

export default Onboadingstep7;
