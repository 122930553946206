import React, { useState, useEffect } from "react";
import "./Css/Collections.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import right from "../Assets/rightArrow_white.svg";
import leftArrowIcon from "../Assets/leftArrowIcon.svg";
import rightArrowSvg from "../Assets/rightArrowIcon.svg";
import { APL_LINK } from "../ServiceConnection/serviceconnection";
import { handleLinkClick } from "../CommonJquery/CommonJquery";
import { Link } from "react-router-dom";
import $ from "jquery";
const Collections = ({ data, SEOloop, ImageLink }) => {
  //browse cities section

  // Custom Next Arrow
  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <img
        className={className}
        src={rightArrowSvg}
        style={{ ...style, display: "block" }}
        onClick={onClick}
        alt="dfoodo"
      />
    );
  };

  // Custom Prev Arrow
  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <img
        src={leftArrowIcon}
        className={className}
        style={{
          ...style,
          display: "block",
          opacity: "1",
        }}
        alt="dfoodo"
        onClick={onClick}
      />
    );
  };
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const match_and_return_seo_link = (v_id) => {
    let data_seo_link_final = "/restro/by_category/" + v_id;
    let data_seo_link = data_seo_link_final;
    if (SEOloop) {
      const matchedItem = SEOloop.find((data) => {
        return data_seo_link === data.call_function_name;
      });

      if (matchedItem) {
        data_seo_link_final = matchedItem.pretty_function_name;
      }
    }
    return data_seo_link_final;
  };
  //mobile condition
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 992) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobile]);
  return (
    <section>
      <div className="browse_otherCities_section">
        <div className="container-lg padding0">
          <div className="cities_mapped ">
            {isMobile ? (
              <div className="scroll-container">
                {data &&
                  data.length > 0 &&
                  data.map((venue, index) => (
                    <div key={index} className="city-item">
                      <Link
                        onClick={() =>
                          handleLinkClick(
                            match_and_return_seo_link(venue.primary_id)
                          )
                        }
                      >
                        <LazyLoadImage
                          className="city-image"
                          src={`${
                            APL_LINK + ImageLink + venue.category_master_image
                          }`}
                          alt={`Venue ${index + 1}`}
                        />
                        <div className="city_description">
                          <h6>{venue.category_master_name}</h6>
                          <span className="d-flex flex-row">
                            <p>{venue.category_count} Places</p>
                            <img src={right} alt="right" />
                          </span>
                        </div>
                      </Link>
                    </div>
                  ))}
              </div>
            ) : (
              <>
                {data && data.length > 0 && (
                  <Slider {...settings}>
                    {data &&
                      data.length > 0 &&
                      data.map((venue, index) => (
                        <div key={index} className="city-item">
                          <Link
                            onClick={() =>
                              handleLinkClick(
                                match_and_return_seo_link(venue.primary_id)
                              )
                            }
                          >
                            <article className="collection_card">
                              <LazyLoadImage
                                className="collection_card__background"
                                src={`${
                                  APL_LINK +
                                  ImageLink +
                                  venue.category_master_image
                                }`}
                                alt={venue.category_master_name}
                                width="1920"
                                height="2193"
                              />
                              <div className="collection_card__content | flow">
                                <div className="collection_card__content--container | flow">
                                  <h2 className="collection_card__title">
                                    {venue.category_master_name}
                                  </h2>
                                  <p className="collection_card__description">
                                    {venue.category_count} Places{" "}
                                    <img
                                      src={right}
                                      alt="right"
                                      id="right_arrow_small"
                                    />
                                  </p>
                                </div>
                              </div>
                            </article>
                          </Link>
                        </div>
                      ))}
                  </Slider>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Collections;
